// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/ball.gif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/GoldenGoalBackgroundChat.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spitfire_canvas__m4dIE {
  position: absolute;
  z-index: 1;
}

.spitfire_planeContainer__KYC-L {
  width: 180px;
  height: 80px;

  position: absolute;
  z-index: 1000;
  transform: translate(0px, 100vh);
}

.spitfire_plane__ckweW {
  width: 180px;
  height: 80px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center/contain no-repeat;
  position: absolute;
}

.spitfire_plane2__1iWZg {
  width: 180px;
  height: 80px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center/contain no-repeat;
  position: absolute;
  opacity: 0;
}

.spitfire_flewaway__5eLQ- {
  position: absolute;
  top: calc(50% - 100px / 2);
  left: calc(50% - 120px / 2);
  opacity: 0;
  font-size: xx-large;
  z-index: 10;
  color: crimson;
}

.spitfire_background__ejwV1 {
  /* background-color: rgb(77, 187, 217); */
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center/contain
    no-repeat;
  background-size: cover;
  width: '100%';
  height: 45vh;
  position: relative;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/spitfire.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,YAAY;;EAEZ,kBAAkB;EAClB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,4EAAiE;EACjE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,4EAAiE;EACjE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,2BAA2B;EAC3B,UAAU;EACV,mBAAmB;EACnB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,yCAAyC;EACzC;aACW;EACX,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".canvas {\n  position: absolute;\n  z-index: 1;\n}\n\n.planeContainer {\n  width: 180px;\n  height: 80px;\n\n  position: absolute;\n  z-index: 1000;\n  transform: translate(0px, 100vh);\n}\n\n.plane {\n  width: 180px;\n  height: 80px;\n  background: url('../../public/ball.gif') center/contain no-repeat;\n  position: absolute;\n}\n\n.plane2 {\n  width: 180px;\n  height: 80px;\n  background: url('../../public/ball.gif') center/contain no-repeat;\n  position: absolute;\n  opacity: 0;\n}\n\n.flewaway {\n  position: absolute;\n  top: calc(50% - 100px / 2);\n  left: calc(50% - 120px / 2);\n  opacity: 0;\n  font-size: xx-large;\n  z-index: 10;\n  color: crimson;\n}\n\n.background {\n  /* background-color: rgb(77, 187, 217); */\n  background: url('../../public/GoldenGoalBackgroundChat.jpg') center/contain\n    no-repeat;\n  background-size: cover;\n  width: '100%';\n  height: 45vh;\n  position: relative;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": `spitfire_canvas__m4dIE`,
	"planeContainer": `spitfire_planeContainer__KYC-L`,
	"plane": `spitfire_plane__ckweW`,
	"plane2": `spitfire_plane2__1iWZg`,
	"flewaway": `spitfire_flewaway__5eLQ-`,
	"background": `spitfire_background__ejwV1`
};
export default ___CSS_LOADER_EXPORT___;
