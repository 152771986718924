import { AwesomeButtonProgress } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';

// import 'react-awesome-button/dist/themes/theme-blue.css';
import './App.css';

import { createRef, useEffect, useState } from 'react';
import PhotoSlider from './components/PhotoSlider';
import { leagues } from './constants/leagues';
import Radio from './components/3DRadio';
import LeagueSelection from './components/LeagueSelection';
import MatchSelection from './components/MatchSelection';
import { Howl } from 'howler';
import { Button, Modal } from 'antd';

function App() {
  const [currentPage, setCurrentPage] = useState('leagueSelection');
  const [selectedTeamKey, setSelectedTeamKey] = useState('');
  const [type, setType] = useState('matchOdds');
  const [league, setLeague] = useState({});
  const [initialModalVisible, setInitialModalVisible] = useState(true);
  const [selectedFixtures, setSelectedFixtures] = useState([]);

  return (
    <div className="App">
      <div className="AppContainer">
        <div className="Container">
          {currentPage === 'leagueSelection' && (
            <LeagueSelection
              onFinish={(key, type, league) => {
                setSelectedTeamKey(key);
                setType(type);
                setLeague(league);
                setCurrentPage('matchSelection');
              }}
            />
          )}
          {currentPage === 'matchSelection' && (
            <MatchSelection
              selectedFixtures={selectedFixtures}
              setSelectedFixtures={setSelectedFixtures}
              league={league}
              teamKey={selectedTeamKey}
              type={type}
              onBack={() => {
                setCurrentPage('leagueSelection');
              }}
            />
          )}
        </div>
      </div>

      <Modal
        open={initialModalVisible}
        onCancel={() => {
          setInitialModalVisible(false);
        }}
        onOk={() => {
          setInitialModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            justifyContent: 'space-between',
            padding: 20,
          }}
        >
          <div>
            Explore Season MAX, a distinctive betting system that enables your
            customers to place season-long bets.
          </div>
          <div>
            Simply add a minimum of 5 selections to your betslip to enhance your
            betting experience with our unique Bonus MAX feature!
          </div>
          <Button
            style={{ width: 200 }}
            onClick={() => setInitialModalVisible(false)}
            type="primary"
          >
            GOTCHA!
          </Button>
          <div>
            For more details,{' '}
            <a href="mailto:info@accamax.com">info@accamax.com</a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
