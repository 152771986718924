export const leagues = [
  {
    name: 'Premier League',
    key: 'premierLeague',
    path: 'leagues/premier_league.png',
    teams: [
      {
        name: 'Arsenal',
        key: 'premierLeague-arsenal',
        path: 'leagues/premierLeague/Arsenal.png',
      },
      {
        name: 'Aston Villa',
        key: 'premierLeague-astonVilla',
        path: 'leagues/premierLeague/Aston_Villa.png',
      },
      {
        name: 'AFC Bournemouth',
        key: 'premierLeague-bournemouth',
        path: 'leagues/premierLeague/Bournemouth.png',
      },
      {
        name: 'Brentford',
        key: 'premierLeague-brentford',
        path: 'leagues/premierLeague/Brentford.png',
      },
      {
        name: 'Brighton & Hove Albion',
        key: 'premierLeague-brightonHoveAlbion',
        path: 'leagues/premierLeague/Brighton_&_Hove_Albion.png',
      },
      {
        name: 'Burnley',
        key: 'premierLeague-burnley',
        path: 'leagues/premierLeague/Burnley.png',
      },
      {
        name: 'Chelsea',
        key: 'premierLeague-chelsea',
        path: 'leagues/premierLeague/Chelsea.png',
      },
      {
        name: 'Crystal Palace',
        key: 'premierLeague-crystalPalace',
        path: 'leagues/premierLeague/Crystal_Palace.png',
      },
      {
        name: 'Everton',
        key: 'premierLeague-everton',
        path: 'leagues/premierLeague/Everton.png',
      },
      {
        name: 'Fulham',
        key: 'premierLeague-fulham',
        path: 'leagues/premierLeague/Fulham.png',
      },
      {
        name: 'Liverpool',
        key: 'premierLeague-liverpool',
        path: 'leagues/premierLeague/Liverpool.png',
      },
      {
        name: 'Manchester City',
        key: 'premierLeague-manchesterCity',
        path: 'leagues/premierLeague/Manchester_City.png',
      },
      {
        name: 'Manchester United',
        key: 'premierLeague-manchesterUnited',
        path: 'leagues/premierLeague/Manchester_United.png',
      },
      {
        name: 'Newcastle United',
        key: 'premierLeague-newcastleUnited',
        path: 'leagues/premierLeague/Newcastle_United.png',
      },
      {
        name: 'Sheffield United',
        key: 'premierLeague-sheffieldUnited',
        path: 'leagues/premierLeague/Sheffield_United.png',
      },
      {
        name: 'Luton Town',
        key: 'premierLeague-LutonTown',
        path: 'leagues/premierLeague/Luton_Town.png',
      },
      {
        name: 'Tottenham Hotspur',
        key: 'premierLeague-tottenhamHotspur',
        path: 'leagues/premierLeague/Tottenham_Hotspur.png',
      },
      {
        name: 'Nottingham Forest',
        key: 'premierLeague-nottinghamForest',
        path: 'leagues/premierLeague/Nottingham_Forest.png',
      },
      {
        name: 'West Ham United',
        key: 'premierLeague-westHamUnited',
        path: 'leagues/premierLeague/West_Ham_United.png',
      },
      {
        name: 'Wolverhampton Wanderers',
        key: 'premierLeague-wolverhamptonWanderers',
        path: 'leagues/premierLeague/Wolverhampton_Wanderers.png',
      },
    ],
  },

  //   {
  //     name: 'Bundesliga',
  //     key: 'bundesliga',
  //     path: 'leagues/bundesliga.png',

  //     teams: [
  //       {
  //         name: 'Fulham',
  //         key: 'bundesliga-fulham',
  //         path: 'leagues/premierLeague/Fulham.png',
  //       },

  //       {
  //         name: 'Brentford',
  //         key: 'bundesliga-brentford',
  //         path: 'leagues/premierLeague/Brentford.png',
  //       },
  //       {
  //         name: 'Liverpool',
  //         key: 'bundesliga-liverpool',
  //         path: 'leagues/premierLeague/Liverpool.png',
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Serie A',
  //     key: 'serieA',
  //     path: 'leagues/serie_a.png',
  //     teams: [
  //       {
  //         name: 'Brentford',
  //         key: 'serieA-brentford',
  //         path: 'leagues/premierLeague/Brentford.png',
  //       },
  //       {
  //         name: 'Liverpool',
  //         key: 'serieA-liverpool',
  //         path: 'leagues/premierLeague/Liverpool.png',
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Ligue 1',
  //     key: 'ligue1',
  //     path: 'leagues/ligue_1.png',
  //     teams: [
  //       {
  //         name: 'Brentford',
  //         key: 'ligue1-brentford',
  //         path: 'leagues/premierLeague/Brentford.png',
  //       },
  //       {
  //         name: 'Liverpool',
  //         key: 'ligue1-liverpool',
  //         path: 'leagues/premierLeague/Liverpool.png',
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Eredivisie',
  //     key: 'eredivisie',
  //     path: 'leagues/eredivisie.png',
  //     teams: [
  //       {
  //         name: 'Liverpool',
  //         key: 'eredivisie-liverpool',
  //         path: 'leagues/premierLeague/Liverpool.png',
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Primeira Liga',
  //     key: 'primeiraLiga',
  //     path: 'leagues/primeira_liga.png',
  //     teams: [
  //       {
  //         name: 'Brentford',
  //         key: 'primeiraLiga-brentford',
  //         path: 'leagues/premierLeague/Brentford.png',
  //       },
  //     ],
  //   },
  {
    name: 'Süper Lig',
    key: 'superLig',
    path: 'leagues/super_lig.png',
    teams: [
      {
        name: 'Adana Demirspor',
        key: 'superLig-adanaDemirspor',
        path: 'leagues/superLig/Adana_Demirspor.png',
      },
      {
        name: 'Alanyaspor',
        key: 'superLig-alanyaspor',
        path: 'leagues/superLig/Alanyaspor.png',
      },
      {
        name: 'Ankaragücü',
        key: 'superLig-ankaragucu',
        path: 'leagues/superLig/Ankaragucu.png',
      },
      {
        name: 'Antalyaspor',
        key: 'superLig-antalyaspor',
        path: 'leagues/superLig/Antalyaspor.png',
      },
      {
        name: 'Beşiktaş',
        key: 'superLig-besiktas',
        path: 'leagues/superLig/Besiktas.png',
      },
      {
        name: 'Rizespor',
        key: 'superLig-caykurRizespor',
        path: 'leagues/superLig/Caykur_Rizespor.png',
      },
      {
        name: 'Fatih Karagümrük',
        key: 'superLig-fatihKaragumruk',
        path: 'leagues/superLig/Fatih_Karagumruk.png',
      },
      {
        name: 'Fenerbahçe',
        key: 'superLig-fenerbahce',
        path: 'leagues/superLig/Fenerbahce.png',
      },
      {
        name: 'Galatasaray',
        key: 'superLig-galatasaray',
        path: 'leagues/superLig/Galatasaray.png',
      },
      {
        name: 'Gaziantep FK',
        key: 'superLig-gaziantepFk',
        path: 'leagues/superLig/Gaziantep_FK.png',
      },
      {
        name: 'Hatayspor',
        key: 'superLig-hatayspor',
        path: 'leagues/superLig/Hatayspor.png',
      },
      {
        name: 'İstanbul Başakşehir',
        key: 'superLig-istanbulBasaksehir',
        path: 'leagues/superLig/Istanbul_Basaksehir.png',
      },
      {
        name: 'İstanbulspor',
        key: 'superLig-istanbulspor',
        path: 'leagues/superLig/Istanbulspor.png',
      },
      {
        name: 'Kasımpaşa',
        key: 'superLig-kasimpasa',
        path: 'leagues/superLig/Kasimpasa.png',
      },
      {
        name: 'Kayserispor',
        key: 'superLig-kayserispor',
        path: 'leagues/superLig/Kayserispor.png',
      },
      {
        name: 'Konyaspor',
        key: 'superLig-konyaspor',
        path: 'leagues/superLig/Konyaspor.png',
      },
      {
        name: 'Pendikspor',
        key: 'superLig-pendikspor',
        path: 'leagues/superLig/Pendikspor.png',
      },
      {
        name: 'Samsunspor',
        key: 'superLig-samsunspor',
        path: 'leagues/superLig/Samsunspor.png',
      },
      {
        name: 'Sivasspor',
        key: 'superLig-sivasspor',
        path: 'leagues/superLig/Sivasspor.png',
      },
      {
        name: 'Trabzonspor',
        key: 'superLig-trabzonspor',
        path: 'leagues/superLig/Trabzonspor.png',
      },
    ],
  },
  {
    name: 'La Liga',
    key: 'laLiga',
    path: 'leagues/la_liga.png',
    teams: [
      {
        name: 'Alavés',
        key: 'laLiga-alaves',
        path: 'leagues/laLiga/Alaves.png',
      },
      {
        name: 'Almeria',
        key: 'laLiga-almeria',
        path: 'leagues/laLiga/Almeria.png',
      },
      {
        name: 'Athletic Bilbao',
        key: 'laLiga-athleticBilbao',
        path: 'leagues/laLiga/Athletic_Bilbao.png',
      },
      {
        name: 'Atlético Madrid',
        key: 'laLiga-atleticoMadrid',
        path: 'leagues/laLiga/Atletico_Madrid.png',
      },
      {
        name: 'Barcelona',
        key: 'laLiga-barcelona',
        path: 'leagues/laLiga/Barcelona.png',
      },
      {
        name: 'Cádiz',
        key: 'laLiga-cadiz',
        path: 'leagues/laLiga/Cadiz.png',
      },
      {
        name: 'Celta Vigo',
        key: 'laLiga-celtaVigo',
        path: 'leagues/laLiga/Celta_Vigo.png',
      },
      {
        name: 'Getafe',
        key: 'laLiga-getafe',
        path: 'leagues/laLiga/Getafe.png',
      },
      {
        name: 'Girona',
        key: 'laLiga-girona',
        path: 'leagues/laLiga/Girona.png',
      },
      {
        name: 'Granada',
        key: 'laLiga-granada',
        path: 'leagues/laLiga/Granada.png',
      },
      {
        name: 'Las Palmas',
        key: 'laLiga-lasPalmas',
        path: 'leagues/laLiga/Las_Palmas.png',
      },
      {
        name: 'Mallorca',
        key: 'laLiga-mallorca',
        path: 'leagues/laLiga/Mallorca.png',
      },
      {
        name: 'Osasuna',
        key: 'laLiga-osasuna',
        path: 'leagues/laLiga/Osasuna.png',
      },
      {
        name: 'Rayo Vallecano',
        key: 'laLiga-rayoVallecano',
        path: 'leagues/laLiga/Rayo_Vallecano.png',
      },
      {
        name: 'Real Betis',
        key: 'laLiga-realBetis',
        path: 'leagues/laLiga/Real_Betis.png',
      },
      {
        name: 'Real Madrid',
        key: 'laLiga-realMadrid',
        path: 'leagues/laLiga/Real_Madrid.png',
      },
      {
        name: 'Real Sociedad',
        key: 'laLiga-realSociedad',
        path: 'leagues/laLiga/Real_Sociedad.png',
      },
      {
        name: 'Sevilla',
        key: 'laLiga-sevilla',
        path: 'leagues/laLiga/Sevilla.png',
      },
      {
        name: 'Valencia',
        key: 'laLiga-valencia',
        path: 'leagues/laLiga/Valencia.png',
      },
      {
        name: 'Villarreal',
        key: 'laLiga-villarreal',
        path: 'leagues/laLiga/Villarreal.png',
      },
    ],
  },
];
