import { useEffect, useState } from 'react';
import './index.css';
import { Howl } from 'howler';

const click = new Howl({
  src: ['/click.mp3'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

const OddSelection = ({
  onClick,
  fixture,
  selectedFixtures,
  type = 'matchOdds',
}) => {
  const [state, setState] = useState('idle');
  useEffect(() => {
    const selected = selectedFixtures.find((f) => f.fixture.id === fixture.id);
    if (selected) setState(selected.bidType);
    else setState('idle');
  }, [fixture.id]);
  useEffect(() => {
    if (selectedFixtures.length === 0) setState('idle');
  }, [selectedFixtures]);
  return (
    <div class="radio-container">
      <input
        checked={state === 'home'}
        onClick={() => {
          click.play();
          setState(state === 'home' ? '' : 'home');

          onClick(
            state === 'home' ? '' : type === 'matchOdds' ? 'home' : 'over'
          );
        }}
        type="radio"
        id="option1"
        name="radio-group"
      />
      <label for="option1">
        {type === 'matchOdds' ? (
          <div style={{ width: 100 }}>
            <div>Home</div>
            <div>{Number(fixture.homeOdd).toFixed(2)}</div>
          </div>
        ) : (
          <div style={{ width: 100 }}>
            <div>Over</div>
            <div>{fixture.overOdd.toFixed(2)}</div>
          </div>
        )}
      </label>

      <input
        checked={state === 'draw'}
        onClick={() => {
          click.play();
          setState(state === 'draw' ? '' : 'draw');
          onClick(
            state === 'draw' ? '' : type === 'matchOdds' ? 'draw' : 'under'
          );
        }}
        type="radio"
        id="option2"
        name="radio-group"
      />
      <label for="option2">
        {type === 'matchOdds' ? (
          <div style={{ width: 100 }}>
            <div>Draw</div>
            <div>{fixture.drawOdd.toFixed(2)}</div>
          </div>
        ) : (
          <div style={{ width: 100 }}>
            <div>Under</div>
            <div>{fixture.underOdd.toFixed(2)}</div>
          </div>
        )}
      </label>

      {type === 'matchOdds' && (
        <>
          <input
            checked={state === 'away'}
            onClick={() => {
              click.play();
              setState(state === 'away' ? '' : 'away');
              onClick(state === 'away' ? '' : 'away');
            }}
            type="radio"
            id="option3"
            name="radio-group"
          />
          <label for="option3">
            <div style={{ width: 100 }}>
              <div>Away</div>
              <div>{fixture.awayOdd.toFixed(2)}</div>
            </div>
          </label>
        </>
      )}
    </div>
  );
};

export default OddSelection;
