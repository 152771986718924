import React, { useEffect, useMemo, useRef } from 'react';
import styles from '../../../src/styles/spitfire.module.css';
import { gsap } from 'gsap';

const Plane = ({ isTabletOrMobile, planeRef }) => {
  const plane = useRef(null);

  useEffect(() => {
    gsap.to(plane.current, {
      duration: 0.3,
      opacity: 1,
      repeat: -1,
      ease: 'none',
      yoyo: true,
    });
  }, [plane]);

  const layout = useMemo(
    () => (
      <div ref={planeRef} className={styles.planeContainer}>
        <div className={styles.plane}></div>
        <div ref={plane} className={styles.plane2}></div>
      </div>
    ),
    []
  );

  return layout;
};

export default Plane;
