import React, { useMemo } from 'react';
import styles from '../../../src/styles/spitfire.module.css';

const Background = ({ isTabletOrMobile, children }) => {
  const layout = useMemo(
    () => (
      <div id="Background" className={styles.background}>
        {children}
      </div>
    ),
    []
  );

  return layout;
};

export default Background;
