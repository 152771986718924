import React, { createRef, useEffect, useState } from 'react';
import { leagues } from '../../constants/leagues';
import OddSelection from '../OddSelection';
import { Avatar, Badge, Button, Input, Modal, Table, InputNumber } from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  BackwardOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { fixture } from '../../constants/fixture';
import { Howl } from 'howler';
import CrashGame from '../CrashGame';

const click = new Howl({
  src: ['/click.mp3'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

function MatchSelection({
  teamKey,
  type,
  onBack,
  league,
  selectedFixtures,
  setSelectedFixtures,
}) {
  const [gameStatus, setGameStatus] = useState('Crashed');
  const confirmShownRef = React.useRef(false);
  const [crashModalVisible, setCrashModalVisible] = useState(false);
  const [minCrash, setMinCrash] = useState(0);
  const [crashPoint, setCrashPoint] = useState(0);
  const [currentCrashPoint, setCurrentCrashPoint] = useState(0);
  const [fixtureIndex, setFixtureIndex] = useState(0);
  const [stake, setStake] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);

  const intervalRef = React.useRef(0);
  const teamFixtures = fixture
    .filter((f) => f.home === teamKey || f.away === teamKey)
    .filter(el=>new Date(el.date).getTime() > new Date().getTime())
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((el) => ({
      ...el,
      homeTeam: league.teams.find((t) => t.key === el.home),
      awayTeam: league.teams.find((t) => t.key === el.away),
    }));

  const currentFixture = teamFixtures[fixtureIndex];

  console.log('teamFixtures', teamFixtures);

  useEffect(() => {
    setGameStatus('Waiting');
  }, []);

  const getRatio = (fixture, bidType) => {
    if (bidType === 'home') {
      return fixture.homeOdd;
    } else if (bidType === 'draw') {
      return fixture.drawOdd;
    } else if (bidType === 'away') {
      return fixture.awayOdd;
    } else if (bidType === 'over') {
      return fixture.overOdd;
    } else if (bidType === 'under') {
      return fixture.underOdd;
    } else return 1;
  };
  const totalRatio = selectedFixtures.reduce(
    (acc, curr) => acc * getRatio(curr.fixture, curr.bidType),
    1
  );

  const maxGain = totalRatio;

  useEffect(() => {
    const minCrash = Math.floor(maxGain * 75) / 100;
    const maxCrash = Math.floor(maxGain * 175) / 100;
    setMinCrash(minCrash);
    setCurrentCrashPoint(minCrash);
    setCrashPoint(
      Math.floor((Math.random() * (maxCrash - minCrash) + minCrash) * 100) / 100
    );
  }, [maxGain]);

  const startCrashAnimation = () => {
    confirmShownRef.current = false;
    console.log('startCrashAnimation', crashPoint);
    setGameStatus('Waiting');
    setCrashModalVisible(true);
    setTimeout(() => {
      intervalRef.current = setInterval(() => {
        if (!intervalRef.current) return;
        setCurrentCrashPoint((prev) => {
          console.log('setCurrentCrashPoint', intervalRef.current);
          let newPoint = prev + prev * 0.01;
          if (newPoint >= crashPoint) {
            clearInterval(intervalRef.current);
            setGameStatus('Crashed');
            setTimeout(() => {
              if (!confirmShownRef.current) {
                confirmShownRef.current = true;
                stopCrashAnimation(true);
              }
            }, 2000);
            newPoint = crashPoint;
          } else {
            setGameStatus('InPlay');
          }
          console.log('newPoint', newPoint);
          return newPoint;
        });
      }, 300);
    }, 1000);
  };

  const stopCrashAnimation = (isCrashed) => {
    clearInterval(intervalRef.current);
    intervalRef.current = 0;
    setCrashModalVisible(false);
    const ratio = isCrashed ? minCrash : currentCrashPoint;

    if (!isCrashed) {
      Modal.success({
        closable: false,
        title: 'Congratulations!',
        content: (
          <div>
            <div>Your bet has been placed at Bonus MAX Odds</div>
            <div>Stake: £{stake}</div>
            <div>Total Crashed Odds: {ratio.toFixed(2)}</div>
            <div>Return: £{(stake * ratio).toFixed(2)}</div>
            <div>Good luck</div>
          </div>
        ),
        onOk() {
          click.play();
          setSelectedFixtures([]);
          onBack();
        },
      });
    } else {
      Modal.success({
        closable: false,
        title: 'Congratulations!',
        content: (
          <div>
            <div>Your bet has been placed at the Crashed Odds</div>
            <div>Stake: £{stake}</div>
            <div>Total Crashed Odds: {ratio.toFixed(2)}</div>
            <div>Return: £{(stake * ratio).toFixed(2)}</div>
            <div>Good luck</div>
          </div>
        ),
        onOk() {
          click.play();
          setSelectedFixtures([]);
          onBack();
        },
      });
    }

    setTimeout(() => {}, 100);
  };

  const onRegularBet = () => {
    Modal.success({
      closable: false,
      title: 'Congratulations!',
      content: (
        <div>
          <div>Your bet has been placed at the Regular Odds</div>
          <div>Stake: £{stake}</div>
          <div>Total Odds: {totalRatio.toFixed(2)}</div>
          <div>Return: £{(stake * totalRatio).toFixed(2)}</div>
          <div>Good luck</div>
        </div>
      ),
      onOk() {
        click.play();
        setSelectedFixtures([]);
        onBack();
      },
    });
  };

  return (
    <>
      <img src="logo.png" alt="logo" className="logo" />
      <div
        style={{
          textAlign: '-webkit-center',
        }}
      >
        <div>{new Date(currentFixture.date).toLocaleDateString()}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
            alignItems: 'center',
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <div style={{ width: 80 }}></div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '90%',
            alignItems: 'center',
          }}
        >
          <div>
            <div style={{ width: 150, marginBottom: '16px', height: 40 }}>
              {currentFixture?.homeTeam?.name}
            </div>

            <img
              style={{
                background: 'white',
                width: 150,
                height: 150,
                borderRadius: 16,
              }}
              src={currentFixture?.homeTeam?.path}
            />
          </div>

          {/* <img
            style={{
              width: 80,
              height: 80,
            }}
            src="vs.gif"
          /> */}
          <div style={{ fontSize: 48, width: 100, marginTop: 32 }}>V</div>
          <div>
            <div style={{ width: 150, marginBottom: '16px', height: 40 }}>
              {currentFixture?.awayTeam?.name}
            </div>
            <img
              style={{
                background: 'white',
                width: 150,
                height: 150,
                borderRadius: 16,
              }}
              src={currentFixture?.awayTeam?.path}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            alignItems: 'center',
          }}
        >
          <Button
            icon={<CaretLeftOutlined style={{ fontSize: '24px' }} />}
            type="text"
            style={{ color: 'white', fontSize: '24px', marginTop: 16 }}
            disabled={fixtureIndex === 0}
            onClick={() => {
              if (fixtureIndex > 0) {
                click.play();
                setFixtureIndex(fixtureIndex - 1);
              }
            }}
          >
            Prev
          </Button>

          <Button
            type="text"
            color="white"
            style={{ color: 'white', fontSize: '24px', marginTop: 16 }}
            disabled={fixtureIndex === teamFixtures.length - 1}
            onClick={() => {
              if (fixtureIndex < teamFixtures.length - 1) {
                click.play();
                setFixtureIndex(fixtureIndex + 1);
              }
            }}
          >
            Next
            <CaretRightOutlined style={{ fontSize: '24px' }} />
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '90%',
          alignItems: 'center',
        }}
      >
        <OddSelection
          fixture={currentFixture}
          selectedFixtures={selectedFixtures}
          onClick={(bidType) => {
            const newFixtures = selectedFixtures.filter(
              (f) => f.fixture.id !== currentFixture.id
            );
            if (bidType) newFixtures.push({ fixture: currentFixture, bidType });
            setSelectedFixtures([...newFixtures]);
          }}
          type={type}
        />
      </div>

      <Badge count={selectedFixtures.length}>
        <Button
          type="default"
          style={{
            color: 'white',
            height: '80px',
            backgroundColor: '#282c34',
            fontSize: 24,
          }}
          onClick={() => {
            click.play();
            setModalVisible(true);
          }}
        >
          <div>Total Odds</div>
          <div>{totalRatio.toFixed(2)}</div>
        </Button>
      </Badge>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '90%',
        }}
      >
        <Button
          size="large"
          ghost
          icon={<BackwardOutlined style={{ fontSize: 24 }} />}
          onClick={() => {
            click.play();
            // setSelectedFixtures([]);
            onBack();
          }}
        />
        <Button
          size="large"
          ghost
          icon={
            <DeleteOutlined
              style={{ color: 'red' }}
              onClick={() => {
                click.play();
                Modal.confirm({
                  title: 'Do you confirm clearing the bet slip?',
                  content: 'This action cannot be undone',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                    setSelectedFixtures([]);
                  },
                });
              }}
            />
          }
        />
      </div>
      <div style={{ height: '32px' }}></div>
      <Modal
        open={modalVisible}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText="Place Bet"
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <div
          style={{
            padding: 20,
          }}
        >
          <Table
            pagination={false}
            // dataSource={[
            //   {
            //     key: '5',
            //     opponent: 'Ankaragucu',
            //     selection: 'Fenerbahce',
            //     odd: 1.32,
            //   },
            // ]}
            dataSource={selectedFixtures.map((f) => ({
              key: f.fixture.id,
              selection:
                f.bidType === 'home'
                  ? f.fixture.homeTeam
                  : f.bidType === 'away'
                  ? f.fixture.awayTeam
                  : f.bidType,
              fixture: f.fixture,
              odd: getRatio(f.fixture, f.bidType),
            }))}
            columns={[
              {
                title: 'Fixture',
                dataIndex: 'fixture',
                key: 'fixture',
                width: 12,
                render: (fixture) => (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <Avatar src={fixture.homeTeam.path} />
                    <div>vs</div>
                    <Avatar src={fixture.awayTeam.path} />
                  </div>
                ),
              },
              {
                title: 'Selection',
                dataIndex: 'selection',
                key: 'selection',
                width: 20,
                render: (selection) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {selection?.name
                      ? selection?.name + ' to win'
                      : selection[0].toUpperCase() + selection.slice(1)}
                  </div>
                ),
              },
              {
                title: 'Odd',
                dataIndex: 'odd',
                key: 'odd',
                render: (odd) => <div>{odd.toFixed(2)}</div>,
                width: 8,
              },
            ]}
            // scroll after 200 px
            scroll={{ y: 300 }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 16,
              alignItems: 'end',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Button
              style={{ width: 30, height: 50 }}
              onClick={() => {
                if (stake > 0) setStake(stake - 1);
              }}
            >
              -
            </Button> */}
                <InputNumber
                  addonBefore={
                    <div
                      onClick={() => {
                        click.play();
                        if (stake > 0) setStake(stake - 1);
                      }}
                    >
                      -
                    </div>
                  }
                  addonAfter={
                    <div
                      onClick={() => {
                        click.play();
                        setStake(stake + 1);
                      }}
                    >
                      +
                    </div>
                  }
                  value={stake}
                  onChange={setStake}
                  style={{ width: 120, fontSize: 22 }}
                />

                {/* <Button
              style={{ width: 30, height: 50 }}
              onClick={() => {
                setStake(stake + 1);
              }}
            >
              +
            </Button> */}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  style={{ width: 40, paddingLeft: 5 }}
                  onClick={() => {
                    click.play();
                    setStake(stake + 5);
                  }}
                >
                  +5
                </Button>
                <Button
                  style={{ width: 40, paddingLeft: 5 }}
                  onClick={() => {
                    click.play();
                    setStake(stake + 10);
                  }}
                >
                  +10
                </Button>
                <Button
                  style={{ width: 40, paddingLeft: 5 }}
                  onClick={() => {
                    click.play();
                    setStake(stake + 20);
                  }}
                >
                  +20
                </Button>
                {/* <Button
              style={{ width: 40, paddingLeft: 5 }}
              onClick={() => {
                setStake(stake + 50);
              }}
            >
              +50
            </Button> */}
              </div>
            </div>
            <div>
              <div style={{ fontSize: 20, textAlign: 'center' }}>
                Total Odds: {totalRatio.toFixed(2)}
              </div>
              <Button
                style={{
                  backgroundColor: 'orange',
                  height: 70,
                  width: 200,
                  float: 'right',
                }}
                onClick={() => {
                  click.play();
                  setModalVisible(false);
                  if (selectedFixtures.length > 4) {
                    Modal.confirm({
                      closable: false,
                      style: {
                        padding: 20,
                      },
                      title: 'Do you want to place your bet with BONUS MAX?',
                      content:
                        'You have the chance to enhance your profit up to 100% with BONUS MAX!',
                      okText: 'BONUS MAX!',
                      okType: 'primary',
                      cancelText: 'Regular Bet',
                      onOk() {
                        click.play();
                        startCrashAnimation();
                      },
                      onCancel() {
                        click.play();
                        onRegularBet();
                      },
                    });
                  } else {
                    onRegularBet();
                  }
                }}
              >
                <div style={{ fontWeight: 600, fontSize: 24 }}>Place Bet</div>
                <div style={{ fontSize: 16 }}>
                  Return {(stake * totalRatio).toFixed(2)}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        style={{
          padding: 0,
        }}
        open={crashModalVisible}
        closable={false}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            zIndex: 100,
            width: '100%',
            textAlign: 'center',
            fontSize: 48,
            color: 'yellow',
          }}
        >
          {currentCrashPoint.toFixed(2) + 'x'}
        </div>
        <div
          style={{
            marginTop: '32px',
          }}
        >
          <CrashGame
            gameStatus={gameStatus}
            animate={gameStatus !== 'Waiting'}
          />
          <Button
            size="large"
            disabled={gameStatus === 'Crashed'}
            style={{
              background:
                gameStatus === 'Crashed' ? 'red' : 'rgb(245, 189, 22)',
              position: 'absolute',
              left: 'calc(50% - 180px)',
              width: '360px',
              height: '100px',
              fontSize: '34px',
              marginTop: '20px',
              color: 'white',
            }}
            onClick={() => {
              click.play();
              stopCrashAnimation(false);
            }}
          >
            <div style={{ margin: -10 }}> BONUS MAX ODDS</div>
            <div>
              {(gameStatus === 'Crashed'
                ? minCrash
                : currentCrashPoint
              ).toFixed(2) + 'x'}
            </div>
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default MatchSelection;
