// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

h2 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.App {
  text-align: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.AppContainer {
  background-color: hsl(220, 13%, 18%);
  height: 100vh;
  width: 100vw;
}

.Container {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
}
.logo {
  width: 100%;
  margin-top: 0px;
  margin-bottom: -5px;
  max-height: 100px;
  min-width: 200px;
  max-width: 500px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flag_and_name_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal,
.ant-modal-content {
  padding: 0px !important;
}
.ant-modal-confirm-body-wrapper {
  padding: 20px !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;;EAEE,uBAAuB;AACzB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":["h1 {\n  margin-top: 4px;\n  margin-bottom: 4px;\n}\n\nh2 {\n  margin-top: 4px;\n  margin-bottom: 4px;\n}\n\n.App {\n  text-align: center;\n  overflow: hidden;\n  width: 100vw;\n  height: 100vh;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.AppContainer {\n  background-color: hsl(220, 13%, 18%);\n  height: 100vh;\n  width: 100vw;\n}\n\n.Container {\n  height: calc(100% - 100px);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  justify-content: space-between;\n}\n\n.App-link {\n  color: #61dafb;\n}\n.logo {\n  width: 100%;\n  margin-top: 0px;\n  margin-bottom: -5px;\n  max-height: 100px;\n  min-width: 200px;\n  max-width: 500px;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.flag_and_name_container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.ant-modal,\n.ant-modal-content {\n  padding: 0px !important;\n}\n.ant-modal-confirm-body-wrapper {\n  padding: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
