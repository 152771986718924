import React, { useMemo } from 'react';
import styles from '../../../src/styles/spitfire.module.css';
import Plane from './Plane';

const Canvas = ({ isTabletOrMobile, width, height, planeRef, canvasRef }) => {
  const layout = useMemo(
    () => (
      <>
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
          className={styles.canvas}
        ></canvas>
        <Plane planeRef={planeRef} isTabletOrMobile={isTabletOrMobile}></Plane>
      </>
    ),
    []
  );

  return layout;
};

export default Canvas;
