import styled from '@emotion/styled';
import React, { useEffect, useMemo, useRef } from 'react';
import useSound from 'use-sound';
import { useMediaQuery } from '@mui/material';
import { Power0, Power1, gsap } from 'gsap';
import Background from './Background';
import Canvas from './Canvas';
import styles from '../../../src/styles/spitfire.module.css';
// import Cloud from './Cloud';
import { useWindowSize } from '../../hooks/useWindowSize';

const CrashGame = React.memo(({ animate, soundOn, gameStatus }) => {
  const isTabletOrMobile = !useMediaQuery('(min-width:1224px)');
  const [width, height] = useWindowSize();

  const element = document.getElementById('Background');
  const canvasWidth = element ? element.offsetWidth : 0;
  const canvasHeight = element ? element.offsetHeight : 0;

  const plane = useRef(null);
  const canvas = useRef(null);
  const gamestatusRef = useRef();

  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);
  const circle5 = useRef(null);

  const oldX = useRef(0);
  const oldY = useRef(0);

  let x = 0;
  const growthRate = isTabletOrMobile ? 0.012 : 0.0065;
  const multiplier = isTabletOrMobile ? 4 : 2;
  const tl = gsap.timeline();

  const [playStart, { stop: stopStart }] = useSound('SpitfireStart.mp3', {
    volume: 0.5,
  });

  const [playFlyAway, { stop: stopFlyAway }] = useSound('SpitfireFlyAway.mp3', {
    volume: 0.5,
  });

  function drawExponentialCurve() {
    if (plane.current) {
      const planeX = Math.max(-50, x - plane.current.clientWidth / 2); // Center the plane horizontally
      const planeY =
        canvasHeight -
        Math.exp(growthRate * x) * multiplier -
        plane.current.clientHeight; // Adjust for the plane's height
      gsap.to(plane.current, {
        duration: 0.2,
        x: planeX,
        y: planeY,
        onUpdate() {
          oldX.current = Number(gsap.getProperty(this.targets()[0], 'x'));
          oldY.current = Number(gsap.getProperty(this.targets()[0], 'y'));
        },
      });
    }

    if (canvas.current) {
      const ctx = canvas.current.getContext('2d');

      if (ctx) {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.beginPath();
        ctx.moveTo(0, canvasHeight);

        for (let i = 0; i <= x; i++) {
          const y = canvasHeight - Math.exp(growthRate * i) * multiplier;
          ctx.lineTo(i, y);
        }

        ctx.lineTo(x, canvasHeight); // Connect the line to the bottom right corner
        ctx.closePath(); // Close the path to form a closed shape
        ctx.fillStyle = '#76ADDA'; // Set the fill color with alpha
        ctx.fill(); // Fill the area under the curve

        ctx.strokeStyle = '#1C8CE8';
        ctx.lineWidth = 2;
        ctx.stroke();
      }
    }
  }

  function animateFlight() {
    if (gamestatusRef.current === 'InPlay') {
      if (x < canvasWidth / (isTabletOrMobile ? 1.25 : 1.15)) {
        x += isTabletOrMobile ? 4 : 8;
        tl.to({}, { duration: 0.01, onComplete: drawExponentialCurve }).to(
          {},
          { duration: 0.01, onComplete: animateFlight }
        );
      } else {
        //Plane is about 3/4 down screen so change flight to keep in view

        gsap.to(plane.current, {
          duration: 1,
          y: oldY.current + 3,
          repeat: -1,
          ease: 'none',
          yoyo: true,
        });
      }
    }
  }

  useEffect(() => {
    gamestatusRef.current = gameStatus;

    if (gameStatus === 'InPlay') {
      animateFlight();

      gsap.fromTo(
        circle1.current,
        { scale: 0, autoAlpha: 1, transformOrigin: 'center center' },
        { duration: 5, scale: 15, autoAlpha: 0, repeat: -1 }
      );
      gsap.fromTo(
        circle2.current,
        { scale: 0, autoAlpha: 1, transformOrigin: 'center center' },
        { delay: 1, duration: 5, scale: 15, autoAlpha: 0, repeat: -1 }
      );
      gsap.fromTo(
        circle3.current,
        { scale: 0, autoAlpha: 1, transformOrigin: 'center center' },
        { delay: 2, duration: 5, scale: 15, autoAlpha: 0, repeat: -1 }
      );
      gsap.fromTo(
        circle4.current,
        { scale: 0, autoAlpha: 1, transformOrigin: 'center center' },
        { delay: 3, duration: 5, scale: 15, autoAlpha: 0, repeat: -1 }
      );
      gsap.fromTo(
        circle5.current,
        { scale: 0, autoAlpha: 1, transformOrigin: 'center center' },
        { delay: 4, duration: 5, scale: 15, autoAlpha: 0, repeat: -1 }
      );
    } else if (gameStatus === 'Crashed') {
      gsap.to('#flewaway', {
        delay: 0.1,
        duration: 1,
        scale: 1.5,
        opacity: 1,
        repeat: 0,
        ease: Power0.easeInOut,
        yoyo: true,
      });

      gsap.to('#ripples', {
        duration: 0.01,
        opacity: 0,
        ease: Power0.easeInOut,
      });

      gsap.fromTo(
        plane.current,
        { x: oldX.current, y: oldY.current },
        { duration: 0.7, x: 600, y: -100, ease: Power1.easeInOut }
      );
    } else if (gameStatus === 'Waiting') {
      if (plane.current) {
        const planeX = -50; // Center the plane horizontally
        const planeY = canvasHeight - plane.current.clientHeight; // Adjust for the plane's height
        gsap.to(plane.current, { duration: 0.1, x: planeX, y: planeY });
      }

      gsap.to('#ripples', {
        duration: 0.01,
        opacity: 0,
        ease: Power0.easeInOut,
      });
    }

    if (soundOn) {
      if (gameStatus === 'InPlay') {
        playStart();
      } else if (gameStatus === 'Crashed') {
        playFlyAway();
      }
    } else {
      stopFlyAway();
      stopStart();
    }

    return () => {
      stopFlyAway();
      stopStart();
    };
  }, [gameStatus, soundOn]);

  const Layout = styled('div')(() => ({
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'initial',
    lineHeight: '16px',
    height: '45vh',
    borderRadius: '8px',
  }));

  const layout = useMemo(
    () => (
      <Layout>
        <Background isTabletOrMobile={isTabletOrMobile}>
          <Canvas
            canvasRef={canvas}
            planeRef={plane}
            width={canvasWidth}
            height={canvasHeight}
            isTabletOrMobile={isTabletOrMobile}
          ></Canvas>
          <div id="flewaway" className={styles.flewaway}>
            MISSED!
          </div>
          {/* <svg
            id="ripples"
            style={{ zIndex: 5 }}
            width={canvasWidth}
            height={canvasHeight}
          >
            <circle
              ref={circle1}
              cx="0"
              cy={canvasHeight}
              r="50"
              fill="#1d99bb"
            />
            <circle
              ref={circle2}
              cx="0"
              cy={canvasHeight}
              r="50"
              fill="#1d99bb"
            />
            <circle
              ref={circle3}
              cx="0"
              cy={canvasHeight}
              r="50"
              fill="#1d99bb"
            />
            <circle
              ref={circle4}
              cx="0"
              cy={canvasHeight}
              r="50"
              fill="#1d99bb"
            />
            <circle
              ref={circle5}
              cx="0"
              cy={canvasHeight}
              r="50"
              fill="#1d99bb"
            />
          </svg> */}
          {/* <Cloud top={20} delay="1s" isTabletOrMobile={isTabletOrMobile} /> */}
          {/* <Cloud top={100} delay="2s" isTabletOrMobile={isTabletOrMobile} /> */}
        </Background>
      </Layout>
    ),
    [animate, width, height, gameStatus, soundOn]
  );

  return layout;
});

export default CrashGame;
