import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Howl } from 'howler';
import { createRef, useEffect, useState } from 'react';
const swipe = new Howl({
  src: ['/swipe.mp3'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

const PhotoSlider = ({
  selectedKey,
  photos,
  onChange,
  height,
  background,
  borderRadius,
  width,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const league = photos[selectedIndex];
  const secondImageRef = createRef();
  const firstImageRef = createRef();
  const [lastDiff, setLastDiff] = useState(0);
  const leftLeague =
    selectedIndex > 0
      ? photos.at(selectedIndex - 1)
      : photos.at(photos.length - 1);
  const rightLeague =
    selectedIndex < photos.length - 1 ? photos[selectedIndex + 1] : photos[0];
  const [touchStart, setTouchStart] = useState({
    x: 0,
    y: 0,
  });

  const secondImageSource = lastDiff > 0 ? leftLeague?.path : rightLeague?.path;

  useEffect(() => {
    firstImageRef.current.src = league?.path;
    photos.forEach(async (league, index) => {
      const imageBlob = await fetch(league?.path).then((res) => res.blob());
      const imageObjectURL = URL.createObjectURL(imageBlob);
      league.path = imageObjectURL;
    });
  }, [photos]);

  useEffect(() => {
    onChange?.(photos[selectedIndex]);
  }, [selectedIndex]);

  useEffect(() => {
    if (!selectedKey) return;
    const selectedPhoto = photos.find((photo) => photo.key === selectedKey);
    setSelectedIndex(photos.findIndex((photo) => photo.key === selectedKey));
    firstImageRef.current.src = selectedPhoto?.path;
  }, [selectedKey]);

  let backgroundStyle = background
    ? '-webkit-linear-gradient(left, transparent ' +
      (lastDiff > 0 ? lastDiff : 0) +
      'px,' +
      (background || 'transparent') +
      ' ' +
      (lastDiff > 0 ? lastDiff : 0) +
      'px ,' +
      (background || 'transparent') +
      ' ' +
      (lastDiff < 0 ? (width || 333) + lastDiff : width || 333) +
      'px ,transparent ' +
      (lastDiff < 0 ? (width || 333) + lastDiff : width || 333) +
      'px)'
    : 'transparent';

  return (
    <div
      style={{
        height: height || '200px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Button
        ghost
        style={{ height: '100%' }}
        icon={
          <CaretLeftOutlined
            style={{ color: 'white', fontSize: 32, fontWeight: 600 }}
          />
        }
        onClick={() => {
          swipe.play();
          setSelectedIndex(
            selectedIndex > 0 ? selectedIndex - 1 : photos.length - 1
          );
          firstImageRef.current.src = leftLeague?.path;
        }}
        type="text"
      />
      <div style={{ width: width || '333px', height: height || '200px' }}>
        <img
          ref={(e) => {
            console.log('ref', e);
            if (e) firstImageRef.current = e;
          }}
          onTouchStart={(e) => {
            setTouchStart({
              x: e.targetTouches[0].clientX,
              y: e.targetTouches[0].clientY,
            });
            setLastDiff(0);
          }}
          onTouchMove={(e) => {
            setLastDiff(e.targetTouches[0].clientX - touchStart.x);
          }}
          onTouchEnd={(e) => {
            console.log(
              'onTouchEnd',
              e.changedTouches[0].clientX,
              e.changedTouches[0].clientY
            );
            const touchEnd = {
              x: e.changedTouches[0].clientX,
              y: e.changedTouches[0].clientY,
            };
            const diffX = touchEnd.x - touchStart.x;
            if (Math.abs(diffX) > (width || 333) / 3) {
              console.log('diffX', diffX);
              swipe.play();
              firstImageRef.current.style.opacity = 0.001;
              setTimeout(() => {
                firstImageRef.current.style.opacity = 1;
              }, 1);
              firstImageRef.current.src = secondImageSource;
              console.log('firstImageRef.current', firstImageRef.current);
              if (diffX > 0) {
                setSelectedIndex(
                  selectedIndex > 0 ? selectedIndex - 1 : photos.length - 1
                );
              } else {
                setSelectedIndex(
                  selectedIndex < photos.length - 1 ? selectedIndex + 1 : 0
                );
              }
            }
            setTouchStart(undefined);
            setLastDiff(0);
          }}
          onDragStart={(e) => {
            console.log('dragstart');
            e.preventDefault();
          }}
          style={{
            width: width || '333px',
            height: height || '200px',
            objectPosition:
              Math.abs(lastDiff) > (width || 333) / 6
                ? `${lastDiff}px 0px`
                : '0px 0px',
            background: backgroundStyle,
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 32,
            zIndex: 2,
            borderRadius: borderRadius || '0px',
            // background: background || 'transparent',
          }}
          // src={league.path}
          alt="league"
        />
        <img
          ref={secondImageRef}
          style={{
            width: width || '333px',
            height: height || '200px',
            objectPosition: '0px 0px',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 32,
            zIndex: 1,
            background: background || 'transparent',
            borderRadius: borderRadius || '0px',
          }}
          src={secondImageSource}
          alt="league"
        />
      </div>

      <Button
        ghost
        style={{ height: '100%' }}
        icon={
          <CaretRightOutlined
            style={{ color: 'white', fontSize: 32, fontWeight: 600 }}
          />
        }
        type="text"
        onClick={() => {
          swipe.play();
          setSelectedIndex(
            selectedIndex < photos.length - 1 ? selectedIndex + 1 : 0
          );
          firstImageRef.current.src = rightLeague?.path;
        }}
      />
    </div>
  );
};

export default PhotoSlider;
