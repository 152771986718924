import { useState } from 'react';
import './index.css';

import { Howl } from 'howler';

const click = new Howl({
  src: ['/click.mp3'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

const Radio = ({ option1, option2, onClick }) => {
  const [option1Checked, setOption1Checked] = useState(true);
  const [option2Checked, setOption2Checked] = useState(false);
  return (
    <div class="radio-container">
      <input
        checked={option1Checked}
        onChange={() => {
          setOption1Checked(true);
          setOption2Checked(false);
          click.play();
        }}
        type="radio"
        id="option1"
        name="radio-group"
      />
      <label for="option1" onClick={() => onClick(option1.key)}>
        <div style={{ width: 120 }}>{option1.label}</div>
      </label>

      <input
        checked={option2Checked}
        onChange={() => {
          setOption1Checked(false);
          setOption2Checked(true);
          click.play();
        }}
        type="radio"
        id="option2"
        name="radio-group"
      />
      <label for="option2" onClick={() => onClick(option2.key)}>
        <div style={{ width: 120 }}>{option2.label}</div>
      </label>
    </div>
  );
};

export default Radio;
