import { AwesomeButtonProgress } from 'react-awesome-button';

import { createRef, useEffect, useState } from 'react';
import PhotoSlider from '../PhotoSlider';
import { leagues } from '../../constants/leagues';
import Radio from '../3DRadio';
import { Howl } from 'howler';

const click = new Howl({
  src: ['/click.mp3'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

function LeagueSelection({ onFinish }) {
  const [league, setLeague] = useState(leagues[0]);
  const [team, setTeam] = useState(leagues[0].teams[0]);
  const [market, setMarket] = useState();

  useEffect(() => {
    setTeam(league.teams[0]);
  }, [league]);
  return (
    <>
      <img src="logo.png" alt="logo" className="logo" />
      <div className="flag_and_name_container">
        <h2>{league.name}</h2>
        <PhotoSlider
          height={120}
          width={(120 * 1920) / 1280}
          photos={leagues}
          onChange={setLeague}
          borderRadius={16}
        />
      </div>

      <div className="flag_and_name_container">
        <h2>{team.name}</h2>
        <PhotoSlider
          height={160}
          width={160}
          borderRadius={16}
          // background={'#282c34'}
          background={'white'}
          selectedKey={team.key}
          photos={league.teams}
          onChange={setTeam}
        />
      </div>

      <Radio
        option1={{ label: 'Match Odds', key: 'matchOdds' }}
        option2={{ label: 'O / U 2.5', key: 'overUnder' }}
        onClick={setMarket}
      />
      <AwesomeButtonProgress
        style={{ fontSize: '24px', height: '60px', marginBottom: 32 }}
        size={'large'}
        type="primary"
        loadingLabel="Loading..."
        onPress={() => {
          click.play();
          setTimeout(() => {
            onFinish(team.key, market, league);
          }, 1000);
        }}
      >
        GO MAX!
      </AwesomeButtonProgress>
    </>
  );
}

export default LeagueSelection;
