const premier = [
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-burnley',
    homeOdd: 1.25,
    drawOdd: 5.5,
    awayOdd: 9.5,
    overOdd: 1.5,
    underOdd: 2.5,
    id: '1707523200000premierLeague-liverpoolpremierLeague-burnley0',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'premierLeague-brentford',
    away: 'premierLeague-liverpool',
    homeOdd: 7,
    drawOdd: 4.75,
    awayOdd: 1.4,
    overOdd: 1.6,
    underOdd: 2.2,
    id: '1708128000000premierLeague-brentfordpremierLeague-liverpool1',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-LutonTown',
    homeOdd: 1.25,
    drawOdd: 5.5,
    awayOdd: 9.5,
    overOdd: 1.75,
    underOdd: 1.9,
    id: '1708732800000premierLeague-liverpoolpremierLeague-LutonTown2',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-liverpool',
    homeOdd: 8,
    drawOdd: 5.5,
    awayOdd: 1.35,
    overOdd: 1.5,
    underOdd: 2.5,
    id: '1709337600000premierLeague-nottinghamForestpremierLeague-liverpool3',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-manchesterCity',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000premierLeague-liverpoolpremierLeague-manchesterCity4',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-everton',
    away: 'premierLeague-liverpool',
    homeOdd: 6,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1710547200000premierLeague-evertonpremierLeague-liverpool5',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 1.35,
    drawOdd: 4.75,
    awayOdd: 9,
    overOdd: 1.55,
    underOdd: 2.25,
    id: '1711756800000premierLeague-liverpoolpremierLeague-brightonHoveAlbion6',
  },
  {
    date: '2025-04-03T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 1.3,
    drawOdd: 5,
    awayOdd: 8,
    overOdd: 1.55,
    underOdd: 2.25,
    id: '1712102400000premierLeague-liverpoolpremierLeague-sheffieldUnited7',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-manchesterUnited',
    away: 'premierLeague-liverpool',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712361600000premierLeague-manchesterUnitedpremierLeague-liverpool8',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-crystalPalace',
    homeOdd: 1.25,
    drawOdd: 5.5,
    awayOdd: 9.5,
    overOdd: 1.75,
    underOdd: 1.9,
    id: '1712966400000premierLeague-liverpoolpremierLeague-crystalPalace9',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-liverpool',
    homeOdd: 7,
    drawOdd: 4.75,
    awayOdd: 1.4,
    overOdd: 1.6,
    underOdd: 2.2,
    id: '1713571200000premierLeague-fulhampremierLeague-liverpool10',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-liverpool',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000premierLeague-westHamUnitedpremierLeague-liverpool11',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-tottenhamHotspur',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000premierLeague-liverpoolpremierLeague-tottenhamHotspur12',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-liverpool',
    homeOdd: 6,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1715385600000premierLeague-astonVillapremierLeague-liverpool13',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-liverpool',
    away: 'premierLeague-wolverhamptonWanderers',
    homeOdd: 1.35,
    drawOdd: 4.75,
    awayOdd: 9,
    overOdd: 1.55,
    underOdd: 2.25,
    id: '1716076800000premierLeague-liverpoolpremierLeague-wolverhamptonWanderers14',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-everton',
    homeOdd: 1.2,
    drawOdd: 8.5,
    awayOdd: 17,
    overOdd: 1.5,
    underOdd: 2.5,
    id: '1707523200000premierLeague-manchesterCitypremierLeague-everton15',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-chelsea',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708128000000premierLeague-manchesterCitypremierLeague-chelsea16',
  },
  {
    date: '2025-02-20T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-brentford',
    homeOdd: 1.2,
    drawOdd: 8.5,
    awayOdd: 17,
    overOdd: 1.5,
    underOdd: 2.5,
    id: '1708387200000premierLeague-manchesterCitypremierLeague-brentford17',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-manchesterCity',
    homeOdd: 7,
    drawOdd: 4.75,
    awayOdd: 1.4,
    overOdd: 1.6,
    underOdd: 2.2,
    id: '1708732800000premierLeague-bournemouthpremierLeague-manchesterCity18',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709337600000premierLeague-manchesterCitypremierLeague-manchesterUnited19',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-brightonHoveAlbion',
    away: 'premierLeague-manchesterCity',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1710547200000premierLeague-brightonHoveAlbionpremierLeague-manchesterCity20',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-arsenal',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000premierLeague-manchesterCitypremierLeague-arsenal21',
  },
  {
    date: '2025-04-03T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-astonVilla',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712102400000premierLeague-manchesterCitypremierLeague-astonVilla22',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-manchesterCity',
    homeOdd: 7,
    drawOdd: 4.75,
    awayOdd: 1.4,
    overOdd: 1.6,
    underOdd: 2.2,
    id: '1712361600000premierLeague-crystalPalacepremierLeague-manchesterCity23',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-LutonTown',
    homeOdd: 1.2,
    drawOdd: 8.5,
    awayOdd: 17,
    overOdd: 1.5,
    underOdd: 2.5,
    id: '1712966400000premierLeague-manchesterCitypremierLeague-LutonTown24',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-manchesterCity',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000premierLeague-tottenhamHotspurpremierLeague-manchesterCity25',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-manchesterCity',
    homeOdd: 7,
    drawOdd: 4.75,
    awayOdd: 1.4,
    overOdd: 1.6,
    underOdd: 2.2,
    id: '1714176000000premierLeague-nottinghamForestpremierLeague-manchesterCity26',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-wolverhamptonWanderers',
    homeOdd: 1.2,
    drawOdd: 8.5,
    awayOdd: 17,
    overOdd: 1.5,
    underOdd: 2.5,
    id: '1714780800000premierLeague-manchesterCitypremierLeague-wolverhamptonWanderers27',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-manchesterCity',
    homeOdd: 7,
    drawOdd: 4.75,
    awayOdd: 1.4,
    overOdd: 1.6,
    underOdd: 2.2,
    id: '1715385600000premierLeague-fulhampremierLeague-manchesterCity28',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-westHamUnited',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716076800000premierLeague-manchesterCitypremierLeague-westHamUnited29',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-arsenal',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1707609600000premierLeague-westHamUnitedpremierLeague-arsenal30',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-arsenal',
    homeOdd: 6,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1708128000000premierLeague-burnleypremierLeague-arsenal31',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'premierLeague-arsenal',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1708732800000premierLeague-arsenalpremierLeague-newcastleUnited32',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-sheffieldUnited',
    away: 'premierLeague-arsenal',
    homeOdd: 6,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1709337600000premierLeague-sheffieldUnitedpremierLeague-arsenal33',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-arsenal',
    away: 'premierLeague-brentford',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000premierLeague-arsenalpremierLeague-brentford34',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-arsenal',
    away: 'premierLeague-chelsea',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1710547200000premierLeague-arsenalpremierLeague-chelsea35',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'premierLeague-arsenal',
    away: 'premierLeague-LutonTown',
    homeOdd: 1.25,
    drawOdd: 5.5,
    awayOdd: 9.5,
    overOdd: 1.75,
    underOdd: 1.9,
    id: '1712016000000premierLeague-arsenalpremierLeague-LutonTown36',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-brightonHoveAlbion',
    away: 'premierLeague-arsenal',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1712361600000premierLeague-brightonHoveAlbionpremierLeague-arsenal37',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-arsenal',
    away: 'premierLeague-astonVilla',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712966400000premierLeague-arsenalpremierLeague-astonVilla38',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-arsenal',
    homeOdd: 6,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1713571200000premierLeague-wolverhamptonWandererspremierLeague-arsenal39',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-arsenal',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714176000000premierLeague-tottenhamHotspurpremierLeague-arsenal40',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-arsenal',
    away: 'premierLeague-bournemouth',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000premierLeague-arsenalpremierLeague-bournemouth41',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-manchesterUnited',
    away: 'premierLeague-arsenal',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1715385600000premierLeague-manchesterUnitedpremierLeague-arsenal42',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-arsenal',
    away: 'premierLeague-everton',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716076800000premierLeague-arsenalpremierLeague-everton43',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1707609600000premierLeague-astonVillapremierLeague-manchesterUnited44',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-astonVilla',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1708128000000premierLeague-fulhampremierLeague-astonVilla45',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-nottinghamForest',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 6,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1708732800000premierLeague-astonVillapremierLeague-nottinghamForest46',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-astonVilla',
    homeOdd: 6,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1709337600000premierLeague-LutonTownpremierLeague-astonVilla47',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-tottenhamHotspur',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000premierLeague-astonVillapremierLeague-tottenhamHotspur48',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-astonVilla',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1710547200000premierLeague-westHamUnitedpremierLeague-astonVilla49',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-wolverhamptonWanderers',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000premierLeague-astonVillapremierLeague-wolverhamptonWanderers50',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-brentford',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 6,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1712361600000premierLeague-astonVillapremierLeague-brentford51',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-bournemouth',
    homeOdd: 2.05,
    drawOdd: 3.75,
    awayOdd: 3.3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000premierLeague-astonVillapremierLeague-bournemouth52',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-astonVilla',
    away: 'premierLeague-chelsea',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714176000000premierLeague-astonVillapremierLeague-chelsea53',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-brightonHoveAlbion',
    away: 'premierLeague-astonVilla',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714780800000premierLeague-brightonHoveAlbionpremierLeague-astonVilla54',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-astonVilla',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716076800000premierLeague-crystalPalacepremierLeague-astonVilla55',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-bournemouth',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1707523200000premierLeague-fulhampremierLeague-bournemouth56',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'premierLeague-newcastleUnited',
    away: 'premierLeague-bournemouth',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1708128000000premierLeague-newcastleUnitedpremierLeague-bournemouth57',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-bournemouth',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709337600000premierLeague-burnleypremierLeague-bournemouth58',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000premierLeague-bournemouthpremierLeague-sheffieldUnited59',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-bournemouth',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1710547200000premierLeague-wolverhamptonWandererspremierLeague-bournemouth60',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-everton',
    homeOdd: 2.05,
    drawOdd: 3.75,
    awayOdd: 3.3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000premierLeague-bournemouthpremierLeague-everton61',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-crystalPalace',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712016000000premierLeague-bournemouthpremierLeague-crystalPalace62',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-bournemouth',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712361600000premierLeague-LutonTownpremierLeague-bournemouth63',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1712966400000premierLeague-bournemouthpremierLeague-manchesterUnited64',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1714176000000premierLeague-bournemouthpremierLeague-brightonHoveAlbion65',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-brentford',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1715385600000premierLeague-bournemouthpremierLeague-brentford66',
  },
  {
    date: '2025-05-17T00:00:00.000Z',
    home: 'premierLeague-bournemouth',
    away: 'premierLeague-LutonTown',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1715904000000premierLeague-bournemouthpremierLeague-LutonTown67',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-chelsea',
    away: 'premierLeague-bournemouth',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1716076800000premierLeague-chelseapremierLeague-bournemouth68',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-brentford',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1707523200000premierLeague-wolverhamptonWandererspremierLeague-brentford69',
  },
  {
    date: '2025-02-26T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-brentford',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1708905600000premierLeague-westHamUnitedpremierLeague-brentford70',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-brentford',
    away: 'premierLeague-chelsea',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1709337600000premierLeague-brentfordpremierLeague-chelsea71',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-brentford',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1710547200000premierLeague-burnleypremierLeague-brentford72',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-brentford',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1711756800000premierLeague-brentfordpremierLeague-manchesterUnited73',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'premierLeague-brentford',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1712016000000premierLeague-brentfordpremierLeague-brightonHoveAlbion74',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-brentford',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712966400000premierLeague-brentfordpremierLeague-sheffieldUnited75',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-brentford',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000premierLeague-LutonTownpremierLeague-brentford76',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-everton',
    away: 'premierLeague-brentford',
    homeOdd: 2.05,
    drawOdd: 3.75,
    awayOdd: 3.3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000premierLeague-evertonpremierLeague-brentford77',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-brentford',
    away: 'premierLeague-fulham',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714780800000premierLeague-brentfordpremierLeague-fulham78',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-brentford',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1716076800000premierLeague-brentfordpremierLeague-newcastleUnited79',
  },
  {
    date: '2025-01-31T00:00:00.000Z',
    home: 'premierLeague-manchesterCity',
    away: 'premierLeague-burnley',
    homeOdd: 1.25,
    drawOdd: 5.5,
    awayOdd: 9.5,
    overOdd: 1.5,
    underOdd: 2.5,
    id: '1706659200000premierLeague-manchesterCitypremierLeague-burnley80',
  },
  {
    date: '2025-02-03T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-fulham',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1706918400000premierLeague-burnleypremierLeague-fulham81',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-burnley',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1708732800000premierLeague-crystalPalacepremierLeague-burnley82',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-burnley',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000premierLeague-westHamUnitedpremierLeague-burnley83',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-chelsea',
    away: 'premierLeague-burnley',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1711756800000premierLeague-chelseapremierLeague-burnley84',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-wolverhamptonWanderers',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712016000000premierLeague-burnleypremierLeague-wolverhamptonWanderers85',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-everton',
    away: 'premierLeague-burnley',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712361600000premierLeague-evertonpremierLeague-burnley86',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1712966400000premierLeague-burnleypremierLeague-brightonHoveAlbion87',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-sheffieldUnited',
    away: 'premierLeague-burnley',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000premierLeague-sheffieldUnitedpremierLeague-burnley88',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-manchesterUnited',
    away: 'premierLeague-burnley',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1714176000000premierLeague-manchesterUnitedpremierLeague-burnley89',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 4.3,
    drawOdd: 3.8,
    awayOdd: 1.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000premierLeague-burnleypremierLeague-newcastleUnited90',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-burnley',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1715385600000premierLeague-tottenhamHotspurpremierLeague-burnley91',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-burnley',
    away: 'premierLeague-nottinghamForest',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1716076800000premierLeague-burnleypremierLeague-nottinghamForest92',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1707523200000premierLeague-tottenhamHotspurpremierLeague-brightonHoveAlbion93',
  },
  {
    date: '2025-02-18T00:00:00.000Z',
    home: 'premierLeague-sheffieldUnited',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708214400000premierLeague-sheffieldUnitedpremierLeague-brightonHoveAlbion94',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'premierLeague-brightonHoveAlbion',
    away: 'premierLeague-everton',
    homeOdd: 1.8,
    drawOdd: 4,
    awayOdd: 3.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1708732800000premierLeague-brightonHoveAlbionpremierLeague-everton95',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709337600000premierLeague-fulhampremierLeague-brightonHoveAlbion96',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-brightonHoveAlbion',
    away: 'premierLeague-nottinghamForest',
    homeOdd: 1.75,
    drawOdd: 4,
    awayOdd: 4.33,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000premierLeague-brightonHoveAlbionpremierLeague-nottinghamForest97',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-brightonHoveAlbion',
    away: 'premierLeague-chelsea',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000premierLeague-brightonHoveAlbionpremierLeague-chelsea98',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-newcastleUnited',
    away: 'premierLeague-brightonHoveAlbion',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1715385600000premierLeague-newcastleUnitedpremierLeague-brightonHoveAlbion99',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-brightonHoveAlbion',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1716076800000premierLeague-brightonHoveAlbionpremierLeague-manchesterUnited100',
  },
  {
    date: '2025-02-12T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-chelsea',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707696000000premierLeague-crystalPalacepremierLeague-chelsea101',
  },
  {
    date: '2025-02-23T00:00:00.000Z',
    home: 'premierLeague-chelsea',
    away: 'premierLeague-tottenhamHotspur',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1708646400000premierLeague-chelseapremierLeague-tottenhamHotspur102',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-chelsea',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000premierLeague-chelseapremierLeague-newcastleUnited103',
  },
  {
    date: '2025-04-03T00:00:00.000Z',
    home: 'premierLeague-chelsea',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712102400000premierLeague-chelseapremierLeague-manchesterUnited104',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-sheffieldUnited',
    away: 'premierLeague-chelsea',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712361600000premierLeague-sheffieldUnitedpremierLeague-chelsea105',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-chelsea',
    away: 'premierLeague-everton',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1712966400000premierLeague-chelseapremierLeague-everton106',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-chelsea',
    away: 'premierLeague-westHamUnited',
    homeOdd: 1.75,
    drawOdd: 4,
    awayOdd: 4.33,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000premierLeague-chelseapremierLeague-westHamUnited107',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-chelsea',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715385600000premierLeague-nottinghamForestpremierLeague-chelsea108',
  },
  {
    date: '2025-02-19T00:00:00.000Z',
    home: 'premierLeague-everton',
    away: 'premierLeague-crystalPalace',
    homeOdd: 2.05,
    drawOdd: 3.75,
    awayOdd: 3.3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708300800000premierLeague-evertonpremierLeague-crystalPalace109',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-crystalPalace',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1709337600000premierLeague-tottenhamHotspurpremierLeague-crystalPalace110',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-LutonTown',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000premierLeague-crystalPalacepremierLeague-LutonTown111',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 3.3,
    drawOdd: 3.75,
    awayOdd: 2.05,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1710547200000premierLeague-crystalPalacepremierLeague-newcastleUnited112',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-crystalPalace',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1711756800000premierLeague-nottinghamForestpremierLeague-crystalPalace113',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-westHamUnited',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000premierLeague-crystalPalacepremierLeague-westHamUnited114',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-crystalPalace',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714176000000premierLeague-fulhampremierLeague-crystalPalace115',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-crystalPalace',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000premierLeague-crystalPalacepremierLeague-manchesterUnited116',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-crystalPalace',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1715385600000premierLeague-wolverhamptonWandererspremierLeague-crystalPalace117',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-everton',
    away: 'premierLeague-westHamUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709337600000premierLeague-evertonpremierLeague-westHamUnited118',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-manchesterUnited',
    away: 'premierLeague-everton',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1709942400000premierLeague-manchesterUnitedpremierLeague-everton119',
  },
  {
    date: '2025-04-03T00:00:00.000Z',
    home: 'premierLeague-newcastleUnited',
    away: 'premierLeague-everton',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1712102400000premierLeague-newcastleUnitedpremierLeague-everton120',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-everton',
    away: 'premierLeague-nottinghamForest',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000premierLeague-evertonpremierLeague-nottinghamForest121',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-everton',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714780800000premierLeague-LutonTownpremierLeague-everton122',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-everton',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1715385600000premierLeague-evertonpremierLeague-sheffieldUnited123',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'premierLeague-manchesterUnited',
    away: 'premierLeague-fulham',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708732800000premierLeague-manchesterUnitedpremierLeague-fulham124',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-fulham',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000premierLeague-wolverhamptonWandererspremierLeague-fulham125',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-tottenhamHotspur',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1710547200000premierLeague-fulhampremierLeague-tottenhamHotspur126',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-sheffieldUnited',
    away: 'premierLeague-fulham',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1711756800000premierLeague-sheffieldUnitedpremierLeague-fulham127',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-fulham',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712016000000premierLeague-nottinghamForestpremierLeague-fulham128',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-fulham',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1712361600000premierLeague-fulhampremierLeague-newcastleUnited129',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-fulham',
    homeOdd: 2.05,
    drawOdd: 3.75,
    awayOdd: 3.3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712966400000premierLeague-westHamUnitedpremierLeague-fulham130',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-fulham',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1716076800000premierLeague-LutonTownpremierLeague-fulham131',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1707523200000premierLeague-LutonTownpremierLeague-sheffieldUnited132',
  },
  {
    date: '2025-02-18T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-manchesterUnited',
    homeOdd: 6,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.75,
    underOdd: 1.95,
    id: '1708214400000premierLeague-LutonTownpremierLeague-manchesterUnited133',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-LutonTown',
    away: 'premierLeague-nottinghamForest',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1710547200000premierLeague-LutonTownpremierLeague-nottinghamForest134',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-LutonTown',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000premierLeague-tottenhamHotspurpremierLeague-LutonTown135',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-LutonTown',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714176000000premierLeague-wolverhamptonWandererspremierLeague-LutonTown136',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-LutonTown',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1715385600000premierLeague-westHamUnitedpremierLeague-LutonTown137',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'premierLeague-manchesterUnited',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1710547200000premierLeague-manchesterUnitedpremierLeague-sheffieldUnited138',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'premierLeague-manchesterUnited',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000premierLeague-manchesterUnitedpremierLeague-newcastleUnited139',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-newcastleUnited',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707523200000premierLeague-nottinghamForestpremierLeague-newcastleUnited140',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'premierLeague-newcastleUnited',
    away: 'premierLeague-wolverhamptonWanderers',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1709337600000premierLeague-newcastleUnitedpremierLeague-wolverhamptonWanderers141',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'premierLeague-newcastleUnited',
    away: 'premierLeague-westHamUnited',
    homeOdd: 1.55,
    drawOdd: 4.3,
    awayOdd: 5.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000premierLeague-newcastleUnitedpremierLeague-westHamUnited142',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-newcastleUnited',
    away: 'premierLeague-tottenhamHotspur',
    homeOdd: 2.05,
    drawOdd: 3.75,
    awayOdd: 3.3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712966400000premierLeague-newcastleUnitedpremierLeague-tottenhamHotspur143',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'premierLeague-newcastleUnited',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1714176000000premierLeague-newcastleUnitedpremierLeague-sheffieldUnited144',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-westHamUnited',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1708128000000premierLeague-nottinghamForestpremierLeague-westHamUnited145',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-nottinghamForest',
    homeOdd: 1.75,
    drawOdd: 4,
    awayOdd: 4.33,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712361600000premierLeague-tottenhamHotspurpremierLeague-nottinghamForest146',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'premierLeague-nottinghamForest',
    away: 'premierLeague-wolverhamptonWanderers',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712966400000premierLeague-nottinghamForestpremierLeague-wolverhamptonWanderers147',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'premierLeague-sheffieldUnited',
    away: 'premierLeague-nottinghamForest',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714780800000premierLeague-sheffieldUnitedpremierLeague-nottinghamForest148',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'premierLeague-tottenhamHotspur',
    away: 'premierLeague-wolverhamptonWanderers',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5.25,
    overOdd: 1.7,
    underOdd: 1.9,
    id: '1708128000000premierLeague-tottenhamHotspurpremierLeague-wolverhamptonWanderers149',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'premierLeague-westHamUnited',
    away: 'premierLeague-tottenhamHotspur',
    homeOdd: 3.8,
    drawOdd: 4,
    awayOdd: 1.8,
    overOdd: 1.55,
    underOdd: 2.3,
    id: '1712016000000premierLeague-westHamUnitedpremierLeague-tottenhamHotspur150',
  },
  {
    date: '2025-05-19T00:00:00.000Z',
    home: 'premierLeague-sheffieldUnited',
    away: 'premierLeague-tottenhamHotspur',
    homeOdd: 4.5,
    drawOdd: 4,
    awayOdd: 1.7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716076800000premierLeague-sheffieldUnitedpremierLeague-tottenhamHotspur151',
  },
  {
    date: '2025-02-25T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-sheffieldUnited',
    homeOdd: 2.4,
    drawOdd: 3.3,
    awayOdd: 2.87,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1708819200000premierLeague-wolverhamptonWandererspremierLeague-sheffieldUnited152',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'premierLeague-wolverhamptonWanderers',
    away: 'premierLeague-westHamUnited',
    homeOdd: 2.87,
    drawOdd: 3.3,
    awayOdd: 2.4,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712361600000premierLeague-wolverhamptonWandererspremierLeague-westHamUnited153',
  },
];

const superLig = [
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'superLig-fenerbahce',
    away: 'superLig-alanyaspor',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707609600000superLig-fenerbahcesuperLig-alanyaspor0',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-caykurRizespor',
    away: 'superLig-fenerbahce',
    homeOdd: 3.2,
    drawOdd: 3.2,
    awayOdd: 2.2,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708128000000superLig-caykurRizesporsuperLig-fenerbahce1',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-fenerbahce',
    away: 'superLig-kasimpasa',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708732800000superLig-fenerbahcesuperLig-kasimpasa2',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-hatayspor',
    away: 'superLig-fenerbahce',
    homeOdd: 2.8,
    drawOdd: 3,
    awayOdd: 2.4,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1709337600000superLig-hataysporsuperLig-fenerbahce3',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-fenerbahce',
    away: 'superLig-pendikspor',
    homeOdd: 1.3,
    drawOdd: 4.5,
    awayOdd: 7,
    overOdd: 2,
    underOdd: 1.7,
    id: '1709942400000superLig-fenerbahcesuperLig-pendikspor4',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-fenerbahce',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1710547200000superLig-trabzonsporsuperLig-fenerbahce5',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-fenerbahce',
    away: 'superLig-adanaDemirspor',
    homeOdd: 1.4,
    drawOdd: 4.2,
    awayOdd: 6,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1712016000000superLig-fenerbahcesuperLig-adanaDemirspor6',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-fatihKaragumruk',
    away: 'superLig-fenerbahce',
    homeOdd: 4,
    drawOdd: 3.2,
    awayOdd: 1.8,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1712361600000superLig-fatihKaragumruksuperLig-fenerbahce7',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-fenerbahce',
    homeOdd: 3,
    drawOdd: 3,
    awayOdd: 2.4,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712966400000superLig-sivassporsuperLig-fenerbahce8',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-fenerbahce',
    away: 'superLig-besiktas',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1713571200000superLig-fenerbahcesuperLig-besiktas9',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-fenerbahce',
    homeOdd: 3.5,
    drawOdd: 2.8,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1714176000000superLig-konyasporsuperLig-fenerbahce10',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-fenerbahce',
    away: 'superLig-kayserispor',
    homeOdd: 1.4,
    drawOdd: 4.2,
    awayOdd: 6,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-fenerbahcesuperLig-kayserispor11',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-galatasaray',
    away: 'superLig-fenerbahce',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715385600000superLig-galatasaraysuperLig-fenerbahce12',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-fenerbahce',
    away: 'superLig-istanbulspor',
    homeOdd: 1.2,
    drawOdd: 5,
    awayOdd: 9,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1715990400000superLig-fenerbahcesuperLig-istanbulspor13',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'superLig-galatasaray',
    away: 'superLig-istanbulBasaksehir',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707523200000superLig-galatasaraysuperLig-istanbulBasaksehir14',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-ankaragucu',
    away: 'superLig-galatasaray',
    homeOdd: 3,
    drawOdd: 3.2,
    awayOdd: 2.2,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708128000000superLig-ankaragucusuperLig-galatasaray15',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-galatasaray',
    away: 'superLig-antalyaspor',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708732800000superLig-galatasaraysuperLig-antalyaspor16',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-besiktas',
    away: 'superLig-galatasaray',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1709337600000superLig-besiktassuperLig-galatasaray17',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-galatasaray',
    away: 'superLig-caykurRizespor',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1709942400000superLig-galatasaraysuperLig-caykurRizespor18',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-kasimpasa',
    away: 'superLig-galatasaray',
    homeOdd: 3.2,
    drawOdd: 3.2,
    awayOdd: 2,
    overOdd: 2,
    underOdd: 1.7,
    id: '1710547200000superLig-kasimpasasuperLig-galatasaray19',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-galatasaray',
    away: 'superLig-hatayspor',
    homeOdd: 1.8,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1712016000000superLig-galatasaraysuperLig-hatayspor20',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-alanyaspor',
    away: 'superLig-galatasaray',
    homeOdd: 3.5,
    drawOdd: 2.8,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1712361600000superLig-alanyasporsuperLig-galatasaray21',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-galatasaray',
    away: 'superLig-pendikspor',
    homeOdd: 1.3,
    drawOdd: 4.5,
    awayOdd: 7,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712966400000superLig-galatasaraysuperLig-pendikspor22',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-adanaDemirspor',
    away: 'superLig-galatasaray',
    homeOdd: 3.8,
    drawOdd: 3,
    awayOdd: 1.9,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1713571200000superLig-adanaDemirsporsuperLig-galatasaray23',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-galatasaray',
    away: 'superLig-sivasspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 2,
    underOdd: 1.7,
    id: '1714176000000superLig-galatasaraysuperLig-sivasspor24',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-fatihKaragumruk',
    away: 'superLig-galatasaray',
    homeOdd: 3.2,
    drawOdd: 3.2,
    awayOdd: 2,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-fatihKaragumruksuperLig-galatasaray25',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-galatasaray',
    homeOdd: 3.5,
    drawOdd: 2.8,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1715990400000superLig-konyasporsuperLig-galatasaray26',
  },
  {
    date: '2025-02-12T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-hatayspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1707696000000superLig-trabzonsporsuperLig-hatayspor27',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-pendikspor',
    away: 'superLig-trabzonspor',
    homeOdd: 3,
    drawOdd: 3.2,
    awayOdd: 2.2,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708128000000superLig-pendiksporsuperLig-trabzonspor28',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-adanaDemirspor',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708732800000superLig-trabzonsporsuperLig-adanaDemirspor29',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-alanyaspor',
    away: 'superLig-trabzonspor',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1709337600000superLig-alanyasporsuperLig-trabzonspor30',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-fatihKaragumruk',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1709942400000superLig-trabzonsporsuperLig-fatihKaragumruk31',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-trabzonspor',
    homeOdd: 3.5,
    drawOdd: 2.8,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1712016000000superLig-konyasporsuperLig-trabzonspor32',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-sivasspor',
    homeOdd: 1.8,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1712361600000superLig-trabzonsporsuperLig-sivasspor33',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-kayserispor',
    away: 'superLig-trabzonspor',
    homeOdd: 3.2,
    drawOdd: 3.2,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1712966400000superLig-kayserisporsuperLig-trabzonspor34',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-gaziantepFk',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 2,
    underOdd: 1.7,
    id: '1713571200000superLig-trabzonsporsuperLig-gaziantepFk35',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-samsunspor',
    away: 'superLig-trabzonspor',
    homeOdd: 3.8,
    drawOdd: 3,
    awayOdd: 1.9,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714176000000superLig-samsunsporsuperLig-trabzonspor36',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-istanbulspor',
    homeOdd: 1.4,
    drawOdd: 4.2,
    awayOdd: 6,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-trabzonsporsuperLig-istanbulspor37',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-istanbulBasaksehir',
    away: 'superLig-trabzonspor',
    homeOdd: 3.5,
    drawOdd: 2.8,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1715385600000superLig-istanbulBasaksehirsuperLig-trabzonspor38',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-trabzonspor',
    away: 'superLig-ankaragucu',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715990400000superLig-trabzonsporsuperLig-ankaragucu39',
  },
  {
    date: '2025-02-12T00:00:00.000Z',
    home: 'superLig-kayserispor',
    away: 'superLig-besiktas',
    homeOdd: 3.5,
    drawOdd: 2.8,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1707696000000superLig-kayserisporsuperLig-besiktas40',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-besiktas',
    away: 'superLig-konyaspor',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708128000000superLig-besiktassuperLig-konyaspor41',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-istanbulspor',
    away: 'superLig-besiktas',
    homeOdd: 4,
    drawOdd: 3.2,
    awayOdd: 1.8,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1708732800000superLig-istanbulsporsuperLig-besiktas42',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-gaziantepFk',
    away: 'superLig-besiktas',
    homeOdd: 3.2,
    drawOdd: 3.2,
    awayOdd: 2,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1709942400000superLig-gaziantepFksuperLig-besiktas43',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-besiktas',
    away: 'superLig-antalyaspor',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1710547200000superLig-besiktassuperLig-antalyaspor44',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-istanbulBasaksehir',
    away: 'superLig-besiktas',
    homeOdd: 3.8,
    drawOdd: 3,
    awayOdd: 1.9,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712016000000superLig-istanbulBasaksehirsuperLig-besiktas45',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-besiktas',
    away: 'superLig-samsunspor',
    homeOdd: 1.4,
    drawOdd: 4.2,
    awayOdd: 6,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1712361600000superLig-besiktassuperLig-samsunspor46',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-besiktas',
    away: 'superLig-ankaragucu',
    homeOdd: 1.2,
    drawOdd: 5,
    awayOdd: 9,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1712966400000superLig-besiktassuperLig-ankaragucu47',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-besiktas',
    away: 'superLig-caykurRizespor',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 2,
    underOdd: 1.7,
    id: '1714176000000superLig-besiktassuperLig-caykurRizespor48',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-alanyaspor',
    away: 'superLig-besiktas',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1714780800000superLig-alanyasporsuperLig-besiktas49',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-besiktas',
    away: 'superLig-hatayspor',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1715385600000superLig-besiktassuperLig-hatayspor50',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-kasimpasa',
    away: 'superLig-besiktas',
    homeOdd: 4,
    drawOdd: 3.2,
    awayOdd: 1.8,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1715990400000superLig-kasimpasasuperLig-besiktas51',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-caykurRizespor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1707609600000superLig-sivassporsuperLig-caykurRizespor52',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-samsunspor',
    away: 'superLig-caykurRizespor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708732800000superLig-samsunsporsuperLig-caykurRizespor53',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-caykurRizespor',
    away: 'superLig-kayserispor',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 2,
    underOdd: 1.7,
    id: '1709337600000superLig-caykurRizesporsuperLig-kayserispor54',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-caykurRizespor',
    away: 'superLig-gaziantepFk',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1710547200000superLig-caykurRizesporsuperLig-gaziantepFk55',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-istanbulspor',
    away: 'superLig-caykurRizespor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712016000000superLig-istanbulsporsuperLig-caykurRizespor56',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-caykurRizespor',
    away: 'superLig-antalyaspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712361600000superLig-caykurRizesporsuperLig-antalyaspor57',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-istanbulBasaksehir',
    away: 'superLig-caykurRizespor',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712966400000superLig-istanbulBasaksehirsuperLig-caykurRizespor58',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-caykurRizespor',
    away: 'superLig-ankaragucu',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1713571200000superLig-caykurRizesporsuperLig-ankaragucu59',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-pendikspor',
    away: 'superLig-caykurRizespor',
    homeOdd: 3,
    drawOdd: 3.2,
    awayOdd: 2.2,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-pendiksporsuperLig-caykurRizespor60',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-caykurRizespor',
    away: 'superLig-kasimpasa',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1715385600000superLig-caykurRizesporsuperLig-kasimpasa61',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-hatayspor',
    away: 'superLig-caykurRizespor',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715990400000superLig-hataysporsuperLig-caykurRizespor62',
  },
  {
    date: '2025-02-12T00:00:00.000Z',
    home: 'superLig-samsunspor',
    away: 'superLig-antalyaspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1707696000000superLig-samsunsporsuperLig-antalyaspor63',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-antalyaspor',
    away: 'superLig-istanbulspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1708128000000superLig-antalyasporsuperLig-istanbulspor64',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-antalyaspor',
    away: 'superLig-gaziantepFk',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1709337600000superLig-antalyasporsuperLig-gaziantepFk65',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-istanbulBasaksehir',
    away: 'superLig-antalyaspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709942400000superLig-istanbulBasaksehirsuperLig-antalyaspor66',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-antalyaspor',
    away: 'superLig-ankaragucu',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1712016000000superLig-antalyasporsuperLig-ankaragucu67',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-antalyaspor',
    away: 'superLig-hatayspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1712966400000superLig-antalyasporsuperLig-hatayspor68',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-fatihKaragumruk',
    away: 'superLig-antalyaspor',
    homeOdd: 3.2,
    drawOdd: 3.2,
    awayOdd: 2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1713571200000superLig-fatihKaragumruksuperLig-antalyaspor69',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-antalyaspor',
    away: 'superLig-pendikspor',
    homeOdd: 1.6,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 2,
    underOdd: 1.7,
    id: '1714176000000superLig-antalyasporsuperLig-pendikspor70',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-kasimpasa',
    away: 'superLig-antalyaspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-kasimpasasuperLig-antalyaspor71',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-antalyaspor',
    away: 'superLig-adanaDemirspor',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715385600000superLig-antalyasporsuperLig-adanaDemirspor72',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-alanyaspor',
    away: 'superLig-antalyaspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1715990400000superLig-alanyasporsuperLig-antalyaspor73',
  },
  {
    date: '2025-02-09T00:00:00.000Z',
    home: 'superLig-adanaDemirspor',
    away: 'superLig-kasimpasa',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1707436800000superLig-adanaDemirsporsuperLig-kasimpasa74',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-alanyaspor',
    away: 'superLig-adanaDemirspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1708128000000superLig-alanyasporsuperLig-adanaDemirspor75',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-adanaDemirspor',
    away: 'superLig-fatihKaragumruk',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1709337600000superLig-adanaDemirsporsuperLig-fatihKaragumruk76',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-adanaDemirspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1709942400000superLig-konyasporsuperLig-adanaDemirspor77',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-adanaDemirspor',
    away: 'superLig-sivasspor',
    homeOdd: 2.1,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 2,
    underOdd: 1.7,
    id: '1710547200000superLig-adanaDemirsporsuperLig-sivasspor78',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-adanaDemirspor',
    away: 'superLig-kayserispor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712361600000superLig-adanaDemirsporsuperLig-kayserispor79',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-samsunspor',
    away: 'superLig-adanaDemirspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712966400000superLig-samsunsporsuperLig-adanaDemirspor80',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-istanbulspor',
    away: 'superLig-adanaDemirspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1714176000000superLig-istanbulsporsuperLig-adanaDemirspor81',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-adanaDemirspor',
    away: 'superLig-gaziantepFk',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-adanaDemirsporsuperLig-gaziantepFk82',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-adanaDemirspor',
    away: 'superLig-istanbulBasaksehir',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715990400000superLig-adanaDemirsporsuperLig-istanbulBasaksehir83',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-kasimpasa',
    away: 'superLig-fatihKaragumruk',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1708128000000superLig-kasimpasasuperLig-fatihKaragumruk84',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-kasimpasa',
    away: 'superLig-sivasspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1709337600000superLig-kasimpasasuperLig-sivasspor85',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-istanbulspor',
    away: 'superLig-kasimpasa',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1709942400000superLig-istanbulsporsuperLig-kasimpasa86',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-kayserispor',
    away: 'superLig-kasimpasa',
    homeOdd: 2.1,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712016000000superLig-kayserisporsuperLig-kasimpasa87',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-kasimpasa',
    away: 'superLig-konyaspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712361600000superLig-kasimpasasuperLig-konyaspor88',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-gaziantepFk',
    away: 'superLig-kasimpasa',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712966400000superLig-gaziantepFksuperLig-kasimpasa89',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-kasimpasa',
    away: 'superLig-samsunspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1713571200000superLig-kasimpasasuperLig-samsunspor90',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-istanbulBasaksehir',
    away: 'superLig-kasimpasa',
    homeOdd: 2.1,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 2,
    underOdd: 1.7,
    id: '1714176000000superLig-istanbulBasaksehirsuperLig-kasimpasa91',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-ankaragucu',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1707609600000superLig-konyasporsuperLig-ankaragucu92',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-kayserispor',
    away: 'superLig-ankaragucu',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1708732800000superLig-kayserisporsuperLig-ankaragucu93',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-ankaragucu',
    away: 'superLig-istanbulspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709337600000superLig-ankaragucusuperLig-istanbulspor94',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-samsunspor',
    away: 'superLig-ankaragucu',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1709942400000superLig-samsunsporsuperLig-ankaragucu95',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-ankaragucu',
    away: 'superLig-istanbulBasaksehir',
    homeOdd: 2.8,
    drawOdd: 3.2,
    awayOdd: 2.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1710547200000superLig-ankaragucusuperLig-istanbulBasaksehir96',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-ankaragucu',
    away: 'superLig-gaziantepFk',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1712361600000superLig-ankaragucusuperLig-gaziantepFk97',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-ankaragucu',
    away: 'superLig-alanyaspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714176000000superLig-ankaragucusuperLig-alanyaspor98',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-hatayspor',
    away: 'superLig-ankaragucu',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1714780800000superLig-hataysporsuperLig-ankaragucu99',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-ankaragucu',
    away: 'superLig-pendikspor',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715385600000superLig-ankaragucusuperLig-pendikspor100',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-istanbulBasaksehir',
    away: 'superLig-kayserispor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1708128000000superLig-istanbulBasaksehirsuperLig-kayserispor101',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-gaziantepFk',
    away: 'superLig-istanbulBasaksehir',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708732800000superLig-gaziantepFksuperLig-istanbulBasaksehir102',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-istanbulBasaksehir',
    away: 'superLig-samsunspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1709337600000superLig-istanbulBasaksehirsuperLig-samsunspor103',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-pendikspor',
    away: 'superLig-istanbulBasaksehir',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712361600000superLig-pendiksporsuperLig-istanbulBasaksehir104',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-hatayspor',
    away: 'superLig-istanbulBasaksehir',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1713571200000superLig-hataysporsuperLig-istanbulBasaksehir105',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-istanbulBasaksehir',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-sivassporsuperLig-istanbulBasaksehir106',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-fatihKaragumruk',
    away: 'superLig-alanyaspor',
    homeOdd: 2.1,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708732800000superLig-fatihKaragumruksuperLig-alanyaspor107',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-alanyaspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1709942400000superLig-sivassporsuperLig-alanyaspor108',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-alanyaspor',
    away: 'superLig-kayserispor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1710547200000superLig-alanyasporsuperLig-kayserispor109',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-gaziantepFk',
    away: 'superLig-alanyaspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712016000000superLig-gaziantepFksuperLig-alanyaspor110',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-alanyaspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1712966400000superLig-konyasporsuperLig-alanyaspor111',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-alanyaspor',
    away: 'superLig-istanbulspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1713571200000superLig-alanyasporsuperLig-istanbulspor112',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-samsunspor',
    away: 'superLig-alanyaspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1715385600000superLig-samsunsporsuperLig-alanyaspor113',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'superLig-kayserispor',
    away: 'superLig-hatayspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1709942400000superLig-kayserisporsuperLig-hatayspor114',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-pendikspor',
    away: 'superLig-kayserispor',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.6,
    overOdd: 2,
    underOdd: 1.7,
    id: '1713571200000superLig-pendiksporsuperLig-kayserispor115',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-kayserispor',
    away: 'superLig-fatihKaragumruk',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1714176000000superLig-kayserisporsuperLig-fatihKaragumruk116',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-kayserispor',
    away: 'superLig-konyaspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715385600000superLig-kayserisporsuperLig-konyaspor117',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-kayserispor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715990400000superLig-sivassporsuperLig-kayserispor118',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-hatayspor',
    away: 'superLig-sivasspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1708128000000superLig-hataysporsuperLig-sivasspor119',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-pendikspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1708732800000superLig-sivassporsuperLig-pendikspor120',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-fatihKaragumruk',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712016000000superLig-sivassporsuperLig-fatihKaragumruk121',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'superLig-sivasspor',
    away: 'superLig-konyaspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1713571200000superLig-sivassporsuperLig-konyaspor122',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-istanbulspor',
    away: 'superLig-sivasspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1715385600000superLig-istanbulsporsuperLig-sivasspor123',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-hatayspor',
    homeOdd: 2.1,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 2,
    underOdd: 1.7,
    id: '1708732800000superLig-konyasporsuperLig-hatayspor124',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-hatayspor',
    away: 'superLig-samsunspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1710547200000superLig-hataysporsuperLig-samsunspor125',
  },
  {
    date: '2025-04-06T00:00:00.000Z',
    home: 'superLig-hatayspor',
    away: 'superLig-istanbulspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712361600000superLig-hataysporsuperLig-istanbulspor126',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'superLig-gaziantepFk',
    away: 'superLig-hatayspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714176000000superLig-gaziantepFksuperLig-hatayspor127',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'superLig-gaziantepFk',
    away: 'superLig-samsunspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1708128000000superLig-gaziantepFksuperLig-samsunspor128',
  },
  {
    date: '2025-04-02T00:00:00.000Z',
    home: 'superLig-samsunspor',
    away: 'superLig-pendikspor',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 3.2,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1712016000000superLig-samsunsporsuperLig-pendikspor129',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'superLig-konyaspor',
    away: 'superLig-samsunspor',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1714780800000superLig-konyasporsuperLig-samsunspor130',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-fatihKaragumruk',
    away: 'superLig-samsunspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715990400000superLig-fatihKaragumruksuperLig-samsunspor131',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'superLig-istanbulspor',
    away: 'superLig-gaziantepFk',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1707609600000superLig-istanbulsporsuperLig-gaziantepFk132',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'superLig-gaziantepFk',
    away: 'superLig-fatihKaragumruk',
    homeOdd: 1.8,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1715385600000superLig-gaziantepFksuperLig-fatihKaragumruk133',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'superLig-pendikspor',
    away: 'superLig-gaziantepFk',
    homeOdd: 2.1,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 2,
    underOdd: 1.7,
    id: '1715990400000superLig-pendiksporsuperLig-gaziantepFk134',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'superLig-fatihKaragumruk',
    away: 'superLig-pendikspor',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 2,
    underOdd: 1.7,
    id: '1707523200000superLig-fatihKaragumruksuperLig-pendikspor135',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'superLig-pendikspor',
    away: 'superLig-konyaspor',
    homeOdd: 2.1,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 2,
    underOdd: 1.7,
    id: '1709337600000superLig-pendiksporsuperLig-konyaspor136',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-pendikspor',
    away: 'superLig-istanbulspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1710547200000superLig-pendiksporsuperLig-istanbulspor137',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'superLig-fatihKaragumruk',
    away: 'superLig-konyaspor',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1710547200000superLig-fatihKaragumruksuperLig-konyaspor138',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'superLig-istanbulspor',
    away: 'superLig-fatihKaragumruk',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 3,
    overOdd: 2.1,
    underOdd: 1.8,
    id: '1712966400000superLig-istanbulsporsuperLig-fatihKaragumruk139',
  },
];

const laLiga = [
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-girona',
    homeOdd: 1.35,
    drawOdd: 5,
    awayOdd: 8,
    overOdd: 1.7,
    underOdd: 1.8,
    id: '1707523200000laLiga-realMadridlaLiga-girona0',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-realMadrid',
    homeOdd: 8,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1708128000000laLiga-rayoVallecanolaLiga-realMadrid1',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-sevilla',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1708732800000laLiga-realMadridlaLiga-sevilla2',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-realMadrid',
    homeOdd: 4,
    drawOdd: 3.5,
    awayOdd: 2,
    overOdd: 1.9,
    underOdd: 1.9,
    id: '1709337600000laLiga-valencialaLiga-realMadrid3',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-celtaVigo',
    homeOdd: 1.6,
    drawOdd: 3.3,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000laLiga-realMadridlaLiga-celtaVigo4',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-osasuna',
    away: 'laLiga-realMadrid',
    homeOdd: 8,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 1.7,
    underOdd: 2,
    id: '1710547200000laLiga-osasunalaLiga-realMadrid5',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-athleticBilbao',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-realMadridlaLiga-athleticBilbao6',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-realMadrid',
    homeOdd: 9,
    drawOdd: 5,
    awayOdd: 1.3,
    overOdd: 2,
    underOdd: 1.7,
    id: '1712966400000laLiga-mallorcalaLiga-realMadrid7',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-barcelona',
    homeOdd: 1.6,
    drawOdd: 4.2,
    awayOdd: 5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000laLiga-realMadridlaLiga-barcelona8',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-realMadrid',
    homeOdd: 3.5,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1714176000000laLiga-realSociedadlaLiga-realMadrid9',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-cadiz',
    homeOdd: 1.3,
    drawOdd: 5.5,
    awayOdd: 8,
    overOdd: 1.7,
    underOdd: 2,
    id: '1714780800000laLiga-realMadridlaLiga-cadiz10',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-granada',
    away: 'laLiga-realMadrid',
    homeOdd: 8,
    drawOdd: 4.5,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1715385600000laLiga-granadalaLiga-realMadrid11',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-alaves',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715644800000laLiga-realMadridlaLiga-alaves12',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-realMadrid',
    homeOdd: 4,
    drawOdd: 3.5,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715990400000laLiga-villarreallaLiga-realMadrid13',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-realMadrid',
    away: 'laLiga-realBetis',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 2.2,
    underOdd: 1.6,
    id: '1716595200000laLiga-realMadridlaLiga-realBetis14',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-athleticBilbao',
    away: 'laLiga-girona',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 4.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708128000000laLiga-athleticBilbaolaLiga-girona15',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-girona',
    away: 'laLiga-rayoVallecano',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708732800000laLiga-gironalaLiga-rayoVallecano16',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-girona',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709337600000laLiga-mallorcalaLiga-girona17',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-girona',
    away: 'laLiga-osasuna',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000laLiga-gironalaLiga-osasuna18',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-girona',
    homeOdd: 1.6,
    drawOdd: 3.8,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1710547200000laLiga-getafelaLiga-girona19',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-girona',
    away: 'laLiga-realBetis',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1711756800000laLiga-gironalaLiga-realBetis20',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-atleticoMadrid',
    away: 'laLiga-girona',
    homeOdd: 1.25,
    drawOdd: 5,
    awayOdd: 9,
    overOdd: 1.7,
    underOdd: 1.8,
    id: '1712966400000laLiga-atleticoMadridlaLiga-girona21',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-girona',
    away: 'laLiga-cadiz',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1713571200000laLiga-gironalaLiga-cadiz22',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-girona',
    homeOdd: 3.5,
    drawOdd: 3.5,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-lasPalmaslaLiga-girona23',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-girona',
    away: 'laLiga-barcelona',
    homeOdd: 8,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1714780800000laLiga-gironalaLiga-barcelona24',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-girona',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715385600000laLiga-alaveslaLiga-girona25',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-girona',
    away: 'laLiga-villarreal',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715644800000laLiga-gironalaLiga-villarreal26',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-girona',
    homeOdd: 1.6,
    drawOdd: 3.8,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715990400000laLiga-valencialaLiga-girona27',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-girona',
    away: 'laLiga-granada',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1716595200000laLiga-gironalaLiga-granada28',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'laLiga-barcelona',
    away: 'laLiga-granada',
    homeOdd: 1.25,
    drawOdd: 5,
    awayOdd: 9,
    overOdd: 1.7,
    underOdd: 1.8,
    id: '1707609600000laLiga-barcelonalaLiga-granada29',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-celtaVigo',
    away: 'laLiga-barcelona',
    homeOdd: 8,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1708128000000laLiga-celtaVigolaLiga-barcelona30',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-barcelona',
    away: 'laLiga-getafe',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708732800000laLiga-barcelonalaLiga-getafe31',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-athleticBilbao',
    away: 'laLiga-barcelona',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709337600000laLiga-athleticBilbaolaLiga-barcelona32',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-barcelona',
    away: 'laLiga-mallorca',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709942400000laLiga-barcelonalaLiga-mallorca33',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-atleticoMadrid',
    away: 'laLiga-barcelona',
    homeOdd: 1.25,
    drawOdd: 5,
    awayOdd: 9,
    overOdd: 1.7,
    underOdd: 1.8,
    id: '1710547200000laLiga-atleticoMadridlaLiga-barcelona34',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-barcelona',
    away: 'laLiga-lasPalmas',
    homeOdd: 1.3,
    drawOdd: 4.5,
    awayOdd: 8,
    overOdd: 1.7,
    underOdd: 2,
    id: '1711756800000laLiga-barcelonalaLiga-lasPalmas35',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-barcelona',
    homeOdd: 8,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1712966400000laLiga-cadizlaLiga-barcelona36',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-barcelona',
    away: 'laLiga-valencia',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1714176000000laLiga-barcelonalaLiga-valencia37',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-barcelona',
    away: 'laLiga-realSociedad',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715385600000laLiga-barcelonalaLiga-realSociedad38',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-barcelona',
    homeOdd: 8,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1715644800000laLiga-almerialaLiga-barcelona39',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-barcelona',
    away: 'laLiga-rayoVallecano',
    homeOdd: 1.3,
    drawOdd: 4.5,
    awayOdd: 8,
    overOdd: 1.7,
    underOdd: 2,
    id: '1715990400000laLiga-barcelonalaLiga-rayoVallecano40',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-sevilla',
    away: 'laLiga-barcelona',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1716595200000laLiga-sevillalaLiga-barcelona41',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'laLiga-sevilla',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1707609600000laLiga-sevillalaLiga-atleticoMadrid42',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-atleticoMadrid',
    away: 'laLiga-lasPalmas',
    homeOdd: 1.3,
    drawOdd: 4.5,
    awayOdd: 8,
    overOdd: 1.7,
    underOdd: 2,
    id: '1708128000000laLiga-atleticoMadridlaLiga-lasPalmas43',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 8,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1708732800000laLiga-almerialaLiga-atleticoMadrid44',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-atleticoMadrid',
    away: 'laLiga-realBetis',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709337600000laLiga-atleticoMadridlaLiga-realBetis45',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 8,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1709942400000laLiga-cadizlaLiga-atleticoMadrid46',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 3.5,
    drawOdd: 3.5,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-villarreallaLiga-atleticoMadrid47',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000laLiga-alaveslaLiga-atleticoMadrid48',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-atleticoMadrid',
    away: 'laLiga-athleticBilbao',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-atleticoMadridlaLiga-athleticBilbao49',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 6,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1714780800000laLiga-mallorcalaLiga-atleticoMadrid50',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-atleticoMadrid',
    away: 'laLiga-celtaVigo',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715385600000laLiga-atleticoMadridlaLiga-celtaVigo51',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 8,
    drawOdd: 4,
    awayOdd: 1.5,
    overOdd: 2,
    underOdd: 1.9,
    id: '1715644800000laLiga-getafelaLiga-atleticoMadrid52',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-atleticoMadrid',
    away: 'laLiga-osasuna',
    homeOdd: 1.3,
    drawOdd: 4.5,
    awayOdd: 8,
    overOdd: 1.7,
    underOdd: 2,
    id: '1715990400000laLiga-atleticoMadridlaLiga-osasuna53',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-atleticoMadrid',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1716595200000laLiga-realSociedadlaLiga-atleticoMadrid54',
  },
  {
    date: '2025-02-12T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-athleticBilbao',
    homeOdd: 2.2,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1707696000000laLiga-almerialaLiga-athleticBilbao55',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-realBetis',
    away: 'laLiga-athleticBilbao',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708732800000laLiga-realBetislaLiga-athleticBilbao56',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-athleticBilbao',
    homeOdd: 3.5,
    drawOdd: 3.5,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000laLiga-lasPalmaslaLiga-athleticBilbao57',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-athleticBilbao',
    away: 'laLiga-alaves',
    homeOdd: 1.6,
    drawOdd: 3.8,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1710547200000laLiga-athleticBilbaolaLiga-alaves58',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-athleticBilbao',
    away: 'laLiga-villarreal',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1712966400000laLiga-athleticBilbaolaLiga-villarreal59',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-athleticBilbao',
    away: 'laLiga-granada',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 4.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1713571200000laLiga-athleticBilbaolaLiga-granada60',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-athleticBilbao',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000laLiga-getafelaLiga-athleticBilbao61',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-athleticBilbao',
    away: 'laLiga-osasuna',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715385600000laLiga-athleticBilbaolaLiga-osasuna62',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-celtaVigo',
    away: 'laLiga-athleticBilbao',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715644800000laLiga-celtaVigolaLiga-athleticBilbao63',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-athleticBilbao',
    away: 'laLiga-sevilla',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715990400000laLiga-athleticBilbaolaLiga-sevilla64',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-athleticBilbao',
    homeOdd: 3.5,
    drawOdd: 3.5,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716595200000laLiga-rayoVallecanolaLiga-athleticBilbao65',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-osasuna',
    homeOdd: 1.6,
    drawOdd: 3.8,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707523200000laLiga-realSociedadlaLiga-osasuna66',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-realSociedad',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708128000000laLiga-mallorcalaLiga-realSociedad67',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-villarreal',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 4.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708732800000laLiga-realSociedadlaLiga-villarreal68',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-sevilla',
    away: 'laLiga-realSociedad',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709337600000laLiga-sevillalaLiga-realSociedad69',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-granada',
    away: 'laLiga-realSociedad',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709942400000laLiga-granadalaLiga-realSociedad70',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-cadiz',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1710547200000laLiga-realSociedadlaLiga-cadiz71',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-realSociedad',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-alaveslaLiga-realSociedad72',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-almeria',
    homeOdd: 1.4,
    drawOdd: 4,
    awayOdd: 7,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1712966400000laLiga-realSociedadlaLiga-almeria73',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-realSociedad',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1713571200000laLiga-getafelaLiga-realSociedad74',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-lasPalmas',
    homeOdd: 1.3,
    drawOdd: 4.5,
    awayOdd: 8,
    overOdd: 1.7,
    underOdd: 2,
    id: '1714780800000laLiga-realSociedadlaLiga-lasPalmas75',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-realSociedad',
    away: 'laLiga-valencia',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715644800000laLiga-realSociedadlaLiga-valencia76',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-realBetis',
    away: 'laLiga-realSociedad',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715990400000laLiga-realBetislaLiga-realSociedad77',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-valencia',
    homeOdd: 2.2,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1707523200000laLiga-lasPalmaslaLiga-valencia78',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-sevilla',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708128000000laLiga-valencialaLiga-sevilla79',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-granada',
    away: 'laLiga-valencia',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708732800000laLiga-granadalaLiga-valencia80',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-getafe',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709942400000laLiga-valencialaLiga-getafe81',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-valencia',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1710547200000laLiga-villarreallaLiga-valencia82',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-mallorca',
    homeOdd: 1.6,
    drawOdd: 3.8,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-valencialaLiga-mallorca83',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-osasuna',
    away: 'laLiga-valencia',
    homeOdd: 2.8,
    drawOdd: 3.3,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712966400000laLiga-osasunalaLiga-valencia84',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-realBetis',
    homeOdd: 1.9,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000laLiga-valencialaLiga-realBetis85',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-alaves',
    homeOdd: 1.5,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000laLiga-valencialaLiga-alaves86',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-valencia',
    away: 'laLiga-rayoVallecano',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 4.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715385600000laLiga-valencialaLiga-rayoVallecano87',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-celtaVigo',
    away: 'laLiga-valencia',
    homeOdd: 2.8,
    drawOdd: 3.3,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716595200000laLiga-celtaVigolaLiga-valencia88',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-osasuna',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708732800000laLiga-lasPalmaslaLiga-osasuna89',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-lasPalmas',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 4.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709337600000laLiga-getafelaLiga-lasPalmas90',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-almeria',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1710547200000laLiga-lasPalmaslaLiga-almeria91',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-sevilla',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1712966400000laLiga-lasPalmaslaLiga-sevilla92',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-celtaVigo',
    away: 'laLiga-lasPalmas',
    homeOdd: 1.9,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000laLiga-celtaVigolaLiga-lasPalmas93',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-lasPalmas',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715385600000laLiga-mallorcalaLiga-lasPalmas94',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-realBetis',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715644800000laLiga-lasPalmaslaLiga-realBetis95',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-lasPalmas',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 4.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715990400000laLiga-cadizlaLiga-lasPalmas96',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-lasPalmas',
    away: 'laLiga-alaves',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716595200000laLiga-lasPalmaslaLiga-alaves97',
  },
  {
    date: '2025-02-09T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-realBetis',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1707436800000laLiga-cadizlaLiga-realBetis98',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-realBetis',
    away: 'laLiga-alaves',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1708128000000laLiga-realBetislaLiga-alaves99',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-realBetis',
    away: 'laLiga-villarreal',
    homeOdd: 2,
    drawOdd: 3.5,
    awayOdd: 3.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1709942400000laLiga-realBetislaLiga-villarreal100',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-realBetis',
    homeOdd: 3,
    drawOdd: 3.5,
    awayOdd: 2.2,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1710547200000laLiga-rayoVallecanolaLiga-realBetis101',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-realBetis',
    away: 'laLiga-celtaVigo',
    homeOdd: 1.9,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712966400000laLiga-realBetislaLiga-celtaVigo102',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-realBetis',
    away: 'laLiga-sevilla',
    homeOdd: 2.2,
    drawOdd: 3.3,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-realBetislaLiga-sevilla103',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-osasuna',
    away: 'laLiga-realBetis',
    homeOdd: 2.8,
    drawOdd: 3.3,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000laLiga-osasunalaLiga-realBetis104',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-realBetis',
    away: 'laLiga-almeria',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 4.5,
    overOdd: 1.9,
    underOdd: 1.8,
    id: '1715385600000laLiga-realBetislaLiga-almeria105',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-celtaVigo',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707609600000laLiga-getafelaLiga-celtaVigo106',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-getafe',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708128000000laLiga-villarreallaLiga-getafe107',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-sevilla',
    homeOdd: 2.5,
    drawOdd: 3,
    awayOdd: 2.6,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-getafelaLiga-sevilla108',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-getafe',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712966400000laLiga-rayoVallecanolaLiga-getafe109',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-getafe',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-almerialaLiga-getafe110',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-getafe',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715385600000laLiga-cadizlaLiga-getafe111',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-getafe',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715990400000laLiga-alaveslaLiga-getafe112',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-getafe',
    away: 'laLiga-mallorca',
    homeOdd: 1.9,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716595200000laLiga-getafelaLiga-mallorca113',
  },
  {
    date: '2025-02-10T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-villarreal',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707523200000laLiga-alaveslaLiga-villarreal114',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-mallorca',
    homeOdd: 1.9,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708732800000laLiga-alaveslaLiga-mallorca115',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-osasuna',
    away: 'laLiga-alaves',
    homeOdd: 2.4,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709337600000laLiga-osasunalaLiga-alaves116',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-rayoVallecano',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000laLiga-alaveslaLiga-rayoVallecano117',
  },
  {
    date: '2025-04-13T00:00:00.000Z',
    home: 'laLiga-granada',
    away: 'laLiga-alaves',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1712966400000laLiga-granadalaLiga-alaves118',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-alaves',
    away: 'laLiga-celtaVigo',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-alaveslaLiga-celtaVigo119',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-osasuna',
    away: 'laLiga-cadiz',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708128000000laLiga-osasunalaLiga-cadiz120',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-osasuna',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-almerialaLiga-osasuna121',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-osasuna',
    homeOdd: 2.4,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000laLiga-rayoVallecanolaLiga-osasuna122',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-granada',
    away: 'laLiga-osasuna',
    homeOdd: 1.9,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-granadalaLiga-osasuna123',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-osasuna',
    away: 'laLiga-mallorca',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715644800000laLiga-osasunalaLiga-mallorca124',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-osasuna',
    away: 'laLiga-villarreal',
    homeOdd: 2.8,
    drawOdd: 3.2,
    awayOdd: 2.2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716595200000laLiga-osasunalaLiga-villarreal125',
  },
  {
    date: '2025-02-11T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-rayoVallecano',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1707609600000laLiga-mallorcalaLiga-rayoVallecano126',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-cadiz',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709337600000laLiga-rayoVallecanolaLiga-cadiz127',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-celtaVigo',
    away: 'laLiga-rayoVallecano',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-celtaVigolaLiga-rayoVallecano128',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-rayoVallecano',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-villarreallaLiga-rayoVallecano129',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-almeria',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000laLiga-rayoVallecanolaLiga-almeria130',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-rayoVallecano',
    away: 'laLiga-granada',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715644800000laLiga-rayoVallecanolaLiga-granada131',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-granada',
    homeOdd: 1.8,
    drawOdd: 3.5,
    awayOdd: 4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000laLiga-villarreallaLiga-granada132',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-villarreal',
    homeOdd: 3.2,
    drawOdd: 3.2,
    awayOdd: 2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000laLiga-almerialaLiga-villarreal133',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-celtaVigo',
    away: 'laLiga-villarreal',
    homeOdd: 2.4,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000laLiga-celtaVigolaLiga-villarreal134',
  },
  {
    date: '2025-05-11T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-sevilla',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715385600000laLiga-villarreallaLiga-sevilla135',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-granada',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1710547200000laLiga-mallorcalaLiga-granada136',
  },
  {
    date: '2025-04-20T00:00:00.000Z',
    home: 'laLiga-sevilla',
    away: 'laLiga-mallorca',
    homeOdd: 1.6,
    drawOdd: 4,
    awayOdd: 5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1713571200000laLiga-sevillalaLiga-mallorca137',
  },
  {
    date: '2025-04-27T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-mallorca',
    homeOdd: 2.5,
    drawOdd: 3.2,
    awayOdd: 2.4,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714176000000laLiga-cadizlaLiga-mallorca138',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-mallorca',
    away: 'laLiga-almeria',
    homeOdd: 2,
    drawOdd: 3.2,
    awayOdd: 3.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715990400000laLiga-mallorcalaLiga-almeria139',
  },
  {
    date: '2025-02-24T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-celtaVigo',
    homeOdd: 2.4,
    drawOdd: 3.2,
    awayOdd: 2.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1708732800000laLiga-cadizlaLiga-celtaVigo140',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-celtaVigo',
    away: 'laLiga-almeria',
    homeOdd: 1.9,
    drawOdd: 3.5,
    awayOdd: 3,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709337600000laLiga-celtaVigolaLiga-almeria141',
  },
  {
    date: '2025-03-16T00:00:00.000Z',
    home: 'laLiga-sevilla',
    away: 'laLiga-celtaVigo',
    homeOdd: 1.8,
    drawOdd: 3.2,
    awayOdd: 3.5,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1710547200000laLiga-sevillalaLiga-celtaVigo142',
  },
  {
    date: '2025-05-18T00:00:00.000Z',
    home: 'laLiga-granada',
    away: 'laLiga-celtaVigo',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715990400000laLiga-granadalaLiga-celtaVigo143',
  },
  {
    date: '2025-03-09T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-sevilla',
    homeOdd: 3,
    drawOdd: 3.2,
    awayOdd: 1.9,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1709942400000laLiga-almerialaLiga-sevilla144',
  },
  {
    date: '2025-05-04T00:00:00.000Z',
    home: 'laLiga-sevilla',
    away: 'laLiga-granada',
    homeOdd: 1.7,
    drawOdd: 3.5,
    awayOdd: 3.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1714780800000laLiga-sevillalaLiga-granada145',
  },
  {
    date: '2025-05-14T00:00:00.000Z',
    home: 'laLiga-sevilla',
    away: 'laLiga-cadiz',
    homeOdd: 1.9,
    drawOdd: 3.2,
    awayOdd: 3.2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1715644800000laLiga-sevillalaLiga-cadiz146',
  },
  {
    date: '2025-03-30T00:00:00.000Z',
    home: 'laLiga-cadiz',
    away: 'laLiga-granada',
    homeOdd: 2.8,
    drawOdd: 3.2,
    awayOdd: 2.2,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1711756800000laLiga-cadizlaLiga-granada147',
  },
  {
    date: '2025-05-25T00:00:00.000Z',
    home: 'laLiga-almeria',
    away: 'laLiga-cadiz',
    homeOdd: 2.2,
    drawOdd: 3.2,
    awayOdd: 2.8,
    overOdd: 1.8,
    underOdd: 1.9,
    id: '1716595200000laLiga-almerialaLiga-cadiz148',
  },
  {
    date: '2025-02-17T00:00:00.000Z',
    home: 'laLiga-granada',
    away: 'laLiga-almeria',
    homeOdd: 2.1,
    drawOdd: 3,
    awayOdd: 3.5,
    overOdd: 1.8,
    underOdd: 2,
    id: '1708128000000laLiga-granadalaLiga-almeria149',
  },
  {
    date: '2025-03-02T00:00:00.000Z',
    home: 'laLiga-villarreal',
    away: 'laLiga-granada',
    homeOdd: 1.6,
    drawOdd: 4,
    awayOdd: 6,
    overOdd: 1.7,
    underOdd: 2,
    id: '1709337600000laLiga-villarreallaLiga-granada150',
  },
];

export const fixture = [...premier, ...superLig, ...laLiga];
